import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Rating } from '@material-ui/lab';
import React from 'react';
import makeStyles from './classes';

export default function FilterOptionItem(props) {
  const classes = makeStyles();
  const text = [];

  let id = 0;
  
  if (props.stars && Number(props.value)) {
    text.push(
      <Rating key={++id} className={classes.text} id={props.value} defaultValue={Number(props.value)} precision={0.5} readOnly size="small" />
    );
  } else {
    text.push(<ListItemText  key={++id}
      id={props.labelId} 
      primary={props.value}
      className={classes.text} 
      primaryTypographyProps={{
        className: classes.itemText
      }}
      secondaryTypographyProps={{
        className: classes.itemText
      }}
    />);
  }

  if (props.facet !== undefined) {
    text.push(
      <ListItemText key={++id}
        className={classes.check}
        primary={'(' + props.facet.toLocaleString() + ')'}
        primaryTypographyProps={{
          className: classes.itemText,
          variant: "none"
        }}
        secondaryTypographyProps={{
          className: classes.itemText
        }}
      />);
  }
  return (
    <ListItem 
        key={props.id} 
        button 
        onClick={props.handleToggle(props.id)}
        className={classes.item}
    >
        <ListItemIcon key={++id}
          className={classes.check}
        >
          <Checkbox
            edge="start"
            checked={props.checked.indexOf(props.id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': props.labelId }}
            size="small"
          />
        </ListItemIcon>
        {text}
    </ListItem>
  );
}