import './styles.css';

import React from 'react';
import TripAdvisorRating from './rating';

export default function TripAdvisor(props) {
    return (
        <div className='container'>
            <img src='./340_Tripadvisor_logo-512.webp' height={18} />
            <TripAdvisorRating className='box' childClassName='top' {...props} />
        </div>
    );
}