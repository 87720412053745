import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        root: {
          marginBottom: theme.spacing(2),
          padding: 10,
        },
        body1: {
          fontSize: 12,
          display: 'flex',
          flexDirection: 'row'
        },
        type: {
          minWidth: 100
        },
        url: {
          flexGrow: 1
        }
      }))();
}