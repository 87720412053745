import AvailabilityReducerFactory from './AvailabilityReducerFactory';

export const FETCH_AVAILABILITY_DURATIONS_START = 'FETCH_AVAILABILITY_DURATIONS_START';
export const FETCH_AVAILABILITY_DURATIONS_SUCCESS = 'FETCH_AVAILABILITY_DURATIONS_SUCCESS';
export const FETCH_AVAILABILITY_DURATIONS_FAILURE = 'FETCH_AVAILABILITY_DURATIONS_FAILURE';
export const FETCH_AVAILABILITY_DURATIONS_WARNING = 'FETCH_AVAILABILITY_DURATIONS_WARNING';
export const AVAILABILITY_DURATIONS_RESET = 'AVAILABILITY_DURATIONS_RESET';

export const actions = { reset: AVAILABILITY_DURATIONS_RESET, start: FETCH_AVAILABILITY_DURATIONS_START , success: FETCH_AVAILABILITY_DURATIONS_SUCCESS, warning: FETCH_AVAILABILITY_DURATIONS_WARNING, failure: FETCH_AVAILABILITY_DURATIONS_FAILURE };

const defaultData = {
    2: { id: 2, name: 2 },
    3: { id: 3, name: 3 },
    4: { id: 4, name: 4 },
    5: { id: 5, name: 5 },
    6: { id: 6, name: 6 },
    7: { id: 7, name: 7 },
    8: { id: 8, name: 8 },
    9: { id: 9, name: 9 },
    10: { id: 10, name: 10 },
    11: { id: 11, name: 11 },
    12: { id: 12, name: 12 },
    13: { id: 13, name: 13 },
    14: { id: 14, name: 14 },
    15: { id: 15, name: 15 },
    16: { id: 16, name: 16 },
    17: { id: 17, name: 17 },
    21: { id: 21, name: 21 },
    28: { id: 28, name: 28 },
};

function reducer(data) {
    let result = {};

    data.durations.forEach(id => result[id] = { id: id, name: id });

    return result;
}

export default AvailabilityReducerFactory(actions, defaultData, { success: reducer });