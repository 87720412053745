import ChangeSearchState from './ChangeSearchState';
import { FetchAvailableDates } from './FetchAvailabilities';

export default function ChangeDuration(searchState, val, dispatch) {
    const newState = Object.assign(
        {},
        searchState,
        {durations: val}
    );
    FetchAvailableDates(newState, dispatch);
    ChangeSearchState(newState, dispatch);
}