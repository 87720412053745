import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActions, CardMedia } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    flex: {
      display: 'flex',
    },
    image: {
      flex: '1 0 auto',
    },
    content: {
      flex: '1 0 auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    name: {
      flex: '0.45 0 auto',
      fontSize: '1.5em',
    },
    price: {
        flex: '0 0 auto',
        fontSize: '1.3em',
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    stars: {
      flex: '1 0 auto',
      verticalAlign: 'text-top',
    },
    controls: {
      flex: '0 0 auto',
      alignItems: 'right',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    calendar: {
      padding: theme.spacing(1),
    },
    modal: {
    },
    calendarTitle: {
      backgroundColor: theme.palette.primary.main
    },
    block: {
      display: 'block',
    },
    dayDate: {
      margin: 0,
      textAlign: 'right',
      fontWeight: 600,
      position: 'relative',
      top: 2,
    },
    dayPrice: {
      margin: 0,
      textAlign: 'right',
      position: 'relative',
      top: -2,
    },
    destination: {
        width: 200,
    }
  }));

export default function PropertySkeleton() {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <div className={classes.flex}>
                <CardContent className={classes.content}>
                    <div className={classes.flex}>
                        <Skeleton className={classes.name} variant='text' />
                    </div>
                    <Skeleton className={classes.destination} variant='text' />
                </CardContent>
                <CardActions className={classes.controls}>
                    <Skeleton variant='rect' width={140} height={22} />
                </CardActions>
            </div>
            <div className={classes.flex}>
                <CardMedia className={classes.image}>
                    <Skeleton variant='rect' width={50} height={150} />
                </CardMedia>
                <CardContent className={classes.content}>
                    <div className={classes.flex}>
                        <Skeleton className={classes.content} variant='text' />
                        <Skeleton className={classes.price} variant='text' />
                    </div>
                    <div className={classes.flex}>
                        <Skeleton className={classes.content} variant='text' />
                        <Skeleton className={classes.price} variant='text' />
                    </div>
                </CardContent>
            </div>
        </Card>
    );
}