export const FETCH_ATTRIBUTE_START = 'FETCH_ATTRIBUTE_START';
export const FETCH_ATTRIBUTE_SUCCESS = 'FETCH_ATTRIBUTE_SUCCESS';
export const FETCH_ATTRIBUTE_FAILURE = 'FETCH_ATTRIBUTE_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    attributes: [],
    error: false,
};

export default function AttributeLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_ATTRIBUTE_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_ATTRIBUTE_SUCCESS:
            return Object.assign({}, state, { loading: false, loaded: true, error: false, attributes: action.values[0].values });
        case FETCH_ATTRIBUTE_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}