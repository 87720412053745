import { FETCH_FACETS_FAILURE, FETCH_FACETS_START, FETCH_FACETS_SUCCESS, FETCH_FACETS_WARNING } from '../Reducers/FacetReducer';
import { FETCH_PROPERTIES_FAILURE as FETCH_PROPERTIES_FAILURE, FETCH_PROPERTIES_START, FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_WARNING } from '../Reducers/PropertyReducer';

import { FETCH_CONTENT_CLEAR } from '../Reducers/ContentReducer';
import { LOG_CALL } from '../Reducers/LogReducer';
import axios from '../axios';
import { SEARCH_MODE_HOTELS_ONLY } from '../Reducers/SearchToolReducer';

export default function FetchProperties(searchState, filterState, dispatch, minRank = 0, fetchFacets = true, appStoreDispatcher = undefined) {
    const hotelsOnly = searchState.searchMode == SEARCH_MODE_HOTELS_ONLY;
    const searchPathBase = hotelsOnly ? "/hotel" : "";

    const start = formatDate(searchState.departureDates.startDate);
    const end = formatDate(searchState.departureDates.endDate);
    const priceBandID = !!filterState.priceBands ? filterState.priceBands : null;

    const nocache = !!window.location.search && window.location.search.indexOf('nocache') > -1;
    
    if ((searchState.g1s.length || searchState.g2s.length || searchState.g3s.length || !!searchState.theme) && (searchState.departureAirports.length || hotelsOnly)) {
        const searchParams = getSearchParams(nocache, searchState, filterState, priceBandID, minRank, start, end);
        const filterParams = getFacetParams(nocache, searchState, filterState, priceBandID, start, end);

        if (appStoreDispatcher) {
            appStoreDispatcher({ type: 'setSearchUrl', payload: searchParams});
            appStoreDispatcher({ type: 'setFacetsUrl', payload: filterParams});
        }
        if (minRank === 0) {
            dispatch({type: FETCH_CONTENT_CLEAR});
            dispatch({type: FETCH_PROPERTIES_START});
        }
        axios.get(searchPathBase + '/search',
        {
            params: searchParams
        })
        .then(response => {
            dispatch({type: LOG_CALL, value: response.config});
            if (response.data.data.length === 0 && minRank === 0) {
                dispatch({type: FETCH_PROPERTIES_WARNING, value: 'No results returned for this search'});
            } else {
                dispatch({type: FETCH_PROPERTIES_SUCCESS, values: response.data.data, append: minRank > 0 });
            }
        })
        .catch(v => {
            if (v.response && v.response.status >= 400 && v.response.status < 500) {
                dispatch({type: FETCH_PROPERTIES_WARNING, value: 'Search was invalid'});
            } else {
                dispatch({type: FETCH_PROPERTIES_FAILURE});
            }
        });

        if (fetchFacets) {
            axios.get(searchPathBase + '/facets',
            {
                params: filterParams
            })
            .then(response => {
                dispatch({type: LOG_CALL, value: response.config});
                if (response.data.data.length === 0) {
                    dispatch({type: FETCH_FACETS_WARNING, value: 'No facets returned for this search'});
                } else {
                    dispatch({type: FETCH_FACETS_SUCCESS, values: response.data.data});
                }
            })
            .catch(v => {
                if (v.response && v.response.status >= 400 && v.response.status < 500) {
                    dispatch({type: FETCH_FACETS_WARNING, value: 'Search was invalid'});
                } else {
                    dispatch({type: FETCH_FACETS_FAILURE});
                }
            });
          }
    }
}

function getSearchParams(nocache, searchState, filterState, priceBandID, minRank, start, end)
{
    let g1s = searchState.g1s.map(geography => geography.id).join(',');
    let g2s = searchState.g2s.map(geography => geography.id).join(',');
    let g3s = searchState.g3s.map(geography => geography.id).join(',');
    if (filterState.geographies.length) {
        switch (filterState.geographyType) {
            case 'Country':
                g1s = filterState.geographies.join(',');
                g2s = '';
                g3s = '';
                break;
            case 'Region':
                g1s = '';
                g2s = filterState.geographies.join(',');
                g3s = '';
                break;
            case 'Resort':
                g1s = '';
                g2s = '';
                g3s = filterState.geographies.join(',');
                break;

        }
    }
    const params = {
      theme: searchState.theme,
      airports: searchState.departureAirports.map(airport => airport.id).join(','),
      durations: searchState.durations.join(','),
      start,
      end,
      g1s,
      g2s,
      g3s,
      results: searchState.resultsCount,
      mealbases: filterState.mealBases.join(','),
      facilities: filterState.facilities.join(','),
      ratings: filterState.ratings.join(','),
      priceband: priceBandID,
      profile: searchState.profile,
      minrank: minRank,
      mintripadvisorrating: filterState.minTripAdvisorRating,
      rooms: searchState.rooms.map(room => '(' + room.adults + ',' + room.children + ')').join(',')
  };

  if (nocache) {
    params['nocache'] = 1;
  }

  return params;
}

function getFacetParams(nocache, searchState, filterState, priceBandID, start, end)
{
    let filter_g1s = '';
    let filter_g2s = '';
    let filter_g3s = '';
    if (filterState.geographies.length) {
        switch (filterState.geographyType) {
            case 'Country':
                filter_g1s = filterState.geographies.join(',');
                break;
            case 'Region':
                filter_g2s = filterState.geographies.join(',');
                break;
            case 'Resort':
                filter_g3s = filterState.geographies.join(',');
                break;

        }
    }
  const params = {
      theme: searchState.theme,
      airports: searchState.departureAirports.map(airport => airport.id).join(','),
      durations: searchState.durations.join(','),
      start,
      end,
      g1s: searchState.g1s.map(geography => geography.id).join(','),
      g2s: searchState.g2s.map(geography => geography.id).join(','),
      g3s: searchState.g3s.map(geography => geography.id).join(','),
      mealbases: filterState.mealBases.join(','),
      facilities: filterState.facilities.join(','),
      ratings: filterState.ratings.join(','),
      priceband: priceBandID,
      profile: searchState.profile,
      mintripadvisorrating: filterState.minTripAdvisorRating,
      rooms: searchState.rooms.map(room => '(' + room.adults + ',' + room.children + ')').join(','),
      filter_g1s,
      filter_g2s,
      filter_g3s
  };

  if (nocache) {
    params['nocache'] = 1;
  }

  return params;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
