export const LOG_CALL = 'LOG_CALL';

export const defaultState ={
    calls: []
};

export default function LogReducer(state = defaultState, action) {
    switch (action.type) {
        case LOG_CALL:
            const calls = [...state.calls, action.value];
            if (calls.length > 10) {
              for (let i = 0; i < (calls.length-10);i++) {
                calls.shift();
              }
            }
            const newState = Object.assign({}, state, {
              calls: calls
            });
            return newState;
        default:
            return state;
    }
}