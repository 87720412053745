import { Button, ButtonGroup, Card, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, Popover, Switch, Typography } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { DatePicker } from "@material-ui/pickers";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { FetchAvailableMonths, FetchAvailableDates } from '../../Actions/FetchAvailabilities';
import moment from 'moment';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import useStyles from './styles';

export default function DatePickerWrapper(props) {
  const style = useStyles();

  let startDate = moment(props.value.startDate);
  let endDate = moment(props.value.endDate);

  const searchState = useSelector(state => state.SearchTool, shallowEqual);
  const dispatcher = useDispatch();

  function getSingleMonth(start, end) {
    if (isSingleMonth(start, end)) {
        return `${start.toLocaleDateString(undefined, { month: 'long' })} ${start.getFullYear()}`;
    }

    return undefined;
  }

  function isSingleMonth(start, end) {
    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
    const startDay = start.getDate();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth();
    const endDay = end.getDate();
    
    return (startYear === endYear && 
      startMonth === endMonth && 
      startDay === 1 && 
      endDay ===(new Date(startYear, startMonth + 1, 0)).getDate());
  }

  function addDays(days, date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + days);
  }

  function getDisplayText(start, end) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return getSingleMonth(start, end) || (isSameDate(start, end)
      ? start.toLocaleDateString(undefined, options)
      : searchState.dateIsFlexible
        ? `${addDays(3, start).toLocaleDateString(undefined, options)} ± 3 days`
        : `${start.toLocaleDateString(undefined, options)} - ${end.toLocaleDateString(undefined, options)}`);
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const currentYear = props.value.startDate.getFullYear();
  const currentMonth = months[props.value.startDate.getMonth()];
  const [state, updateState] = useState({
    today: new Date(),
    year: currentYear,
    startDate: getInitialStartDate(),
    endDate: getInitialEndDate(),
    monthMode: isSingleMonth(props.value.startDate, props.value.endDate),
    isFlexible: searchState.dateIsFlexible
  });

  function isSameDate(first, second) {
    return first.getFullYear() === second.getFullYear() &&
           first.getMonth() === second.getMonth() &&
           first.getDate() === second.getDate();
  }

  function getInitialStartDate() {
    let startDate = props.value.startDate;
    if (isSingleMonth(props.value.startDate, props.value.endDate)) {
      return startDate;
    }
    return new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + (searchState.dateIsFlexible ? 3 : 0)
    );
  }

  function getInitialEndDate() {
    let endDate = props.value.endDate;
    if (isSingleMonth(props.value.startDate, props.value.endDate)) {
      return endDate;
    }
    return new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - (searchState.dateIsFlexible ? 3 : 0)
    );
  }

  function getMonthVariant(currentYear, stateYear, month) {
    return currentYear === stateYear &&
      currentMonth === month ? "contained" : "outlined";
  }

  function isMonthDisabled(year, month) {
    let disabled = false;
    if (!props.availableMonths.allowAll)
    {
      disabled = true;
      if (props.availableMonths && props.availableMonths.years && props.availableMonths.years.length > 0) {
        var yearAvailable = props.availableMonths.years.find(x => x.year == year);

        if (yearAvailable && yearAvailable.months) {
          const monthNumber = months.indexOf(month)+1;
          disabled = yearAvailable.months.findIndex(m => monthNumber == m) == -1;
        }
      } 
    }
    return disabled;
  }

  function isDateDisabled(date) {
    return !props.availableDates.allowAll && props.availableDates.list.findIndex(function(d) {
      const result = d.toDate().getTime() == date.getTime();
      return result;
    }) == -1;
  }

  function switchYear(year) {
    FetchAvailableMonths(searchState, dispatcher, year);
    updateState({
      ...state,
      year
    });
  }

  function switchMode(monthMode) {
    updateState({
      ...state,
      monthMode
    });
  }

  function selectMonth(year, month) {
    const monthNumber = months.indexOf(month);
    const startDate = new Date(year, monthNumber, 1);
    const endDate = new Date(year, monthNumber + 1, 0);

    updateState({...state, startDate, endDate });
    props.setValue({
      startDate, endDate, dateIsFlexible: false
    });
  }

  function selectDateRange(newStartDate, newEndDate) {
        startDate = !!newStartDate ? newStartDate.toDate() : startDate;
        endDate = !!newEndDate ? newEndDate.toDate() : endDate;

        props.setValue({ startDate, endDate, dateIsFlexible: state.isFlexible });
  }

  function changeStartDate(startDate) {
    selectDateRange(
      moment(new Date(
        startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - (state.isFlexible ? 3 : 0)
      )),
      moment(new Date(
        startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + (state.isFlexible ? 3 : 0)
      ))
    );
  }

  function handleMonthChange(date) {
    return FetchAvailableDates(searchState, dispatcher, date);
  }

  function handleFlexibleChange(event) {
    updateState({
      ...state,
      isFlexible: event.target.checked
    });
  }

  return (
    <PopupState variant='popover'>
      {popupState => {
        return (
          <div className={style.root}>
            <FormControl variant="filled">
              <InputLabel shrink={true}>{props.label}</InputLabel>
              <Button className={style.button} {...bindTrigger(popupState)}>
                {getDisplayText(props.value.startDate, props.value.endDate)}
                <CalendarTodayIcon className={style.icon} />
              </Button>
            </FormControl>
            <Popover {...bindPopover(popupState)}>
              <Card className={style.card}>
                <ButtonGroup disableElevation>
                  <Button 
                    onClick={()=>switchMode(true)}
                    className={state.monthMode ? style.buttonSelected : style.buttonNormal}
                    variant={state.monthMode ? 'contained' : 'outlined'}>Month</Button>
                  <Button 
                    onClick={()=>switchMode(false)}
                    className={state.monthMode ? style.buttonNormal : style.buttonSelected}
                    variant={state.monthMode ? 'outlined' : 'contained'}>Date</Button>
                </ButtonGroup>
                {
                  state.monthMode && <div className={style.monthSelector}>
                      <div className={style.monthHeader}>
                        <IconButton 
                          onClick={() => switchYear(state.year-1)}>
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <Typography className={style.monthHeaderYear}>{state.year}</Typography>
                        <IconButton
                          onClick={() => switchYear(state.year+1)}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </div>
                      <div className={style.months}>
                        {months.map(month => (
                          <Button 
                            key={month}
                            disableElevation={true}
                            disabled={isMonthDisabled(state.year, month)}
                            onClick={()=>selectMonth(state.year, month)}
                            className={(getMonthVariant(currentYear, state.year, month)==="contained" ? style.monthSelected : style.month)} 
                            variant={getMonthVariant(currentYear, state.year, month)}>{month}</Button>
                        ))}
                      </div>
                    </div>
                }
                {
                  !state.monthMode && <div class="datepicker-wrapper">
                    <DatePicker
                      autoOk
                      orientation="landscape"
                      variant="static"
                      openTo="date"
                      onMonthChange={handleMonthChange}
                      disablePast={true}
                      disableToolbar={true}
                      value={props.value.startDate}
                      shouldDisableDate={isDateDisabled}
                      onChange={changeStartDate}
                    />
                    <div className={styles.flexibleWrapper}>
                      <FormGroup row>
                          <FormControlLabel control={<Switch checked={state.isFlexible} onChange={handleFlexibleChange} name="flexible" />} label="Flexible ± 3 days" />
                      </FormGroup>
                    </div>
                  </div>
                }
              </Card>
            </Popover>
          </div>
        )
      }}
    </PopupState>
  )
}