function getDateComponents(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
    
    return [year, month, day];
}

export function toIso8601DateFormat(date) {
    var year, month, day;
    [year, month, day] = getDateComponents(date);

    return [year, month, day].join('-');
}

export function toIso8601YearMonthFormat(date) {
    var year, month, _;
    [year, month, _] = getDateComponents(date);

    return [year, month].join('-');
}