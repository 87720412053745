export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_MORECONTENT_START = 'FETCH_MORECONTENT_START';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';
export const FETCH_CONTENT_WARNING = 'FETCH_CONTENT_WARNING';
export const FETCH_CONTENT_CLEAR = 'FETCH_CONTENT_CLEAR';

const defaultState = {
    loading: false,
    loadingMore: false,
    loaded: false,
    properties: [],
    error: false,
};

export default function ContentReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCH_CONTENT_START:
            return Object.assign({}, state, { loading: true, loadingMore: false, loaded: false, error: false, });
        case FETCH_MORECONTENT_START:
            return Object.assign({}, state, { loading: false, loadingMore: true, loaded: true, error: false, });
        case FETCH_CONTENT_SUCCESS:
            let properties = action.append ? [...state.properties] : [];
            properties.push(...action.values);
            return Object.assign({}, state, { loading: false, loadingMore: false, loaded: true, error: false, properties, });
        case FETCH_CONTENT_FAILURE:
            return Object.assign({}, state, { loading: false, loadingMore: false, loaded: true, error: true, properties: [], });
        case FETCH_CONTENT_CLEAR:
            return defaultState;
        default:
            return state;
    }
}