import AvailabilityReducerFactory from './AvailabilityReducerFactory';
import moment from 'moment';

export const FETCH_AVAILABILITY_DATES_START = 'FETCH_AVAILABILITY_DATES_START';
export const FETCH_AVAILABILITY_DATES_SUCCESS = 'FETCH_AVAILABILITY_DATES_SUCCESS';
export const FETCH_AVAILABILITY_DATES_FAILURE = 'FETCH_AVAILABILITY_DATES_FAILURE';
export const FETCH_AVAILABILITY_DATES_WARNING = 'FETCH_AVAILABILITY_DATES_WARNING';
export const AVAILABILITY_DATES_RESET = 'AVAILABILITY_DATES_RESET';

export const actions = {
    reset: AVAILABILITY_DATES_RESET,
    start: FETCH_AVAILABILITY_DATES_START,
    success: FETCH_AVAILABILITY_DATES_SUCCESS,
    warning: FETCH_AVAILABILITY_DATES_WARNING,
    failure: FETCH_AVAILABILITY_DATES_FAILURE
};

const defaultData = {
    allowAll: true,
      list: []
    };

function reducer(data) {
    const result = { 
        allowAll: false, 
        list: data.departureDates.map(date => moment(date))
    };
    return result;
}

export default AvailabilityReducerFactory(actions, defaultData, { success: reducer });