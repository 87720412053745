import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        root: {
            width: "100%",
            padding: theme.spacing(2),
            color: 'white'
        },
        inputs: {
            display: 'flex',
            padding: theme.spacing(1),
            paddingBottom: 0
        },
        inputsFollow: {
            paddingTop: 0,
            paddingBottom: theme.spacing(1)
        },
        content: {
            flex: '1 1 auto',
        }
    }))();
}