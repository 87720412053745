import { IconButton, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Dropdown from '../Dropdown';
import React from 'react';
import useStyles from './classes';

export default function Room(props) {
    const classes = useStyles();
    const content = [];
    let id = 0;
    content.push(
        <Typography className={classes.roomLabel} key={++id}>
            {'Room ' + (props.id + 1)}
        </Typography>
    );
    console.log(props.close);
    if (props.close) {
        content.push(
            <IconButton key={++id}
                className={classes.roomButton}
                onClick={() => props.handleDelete(props.id)}
            >
                <CloseIcon />
            </IconButton>
        )
    }
    content.push(
        <div className={classes.dropdowns} key={++id}>
            <Dropdown
                label='Adults'
                options={{
                    1: 1,
                    2: 2,
                    3: 3,
                    4: 4,
                    5: 5
                }}
                value={props.adults}
                setValue={value => props.handleChange(props.id, {adults: value})}
            />
            <Dropdown key={++id}
                label='Children'
                options={props.allowedChildren}
                value={props.children}
                setValue={value => props.handleChange(props.id, {children: value})}
            />
        </div>
    );
    return(
        <div className='Room'>
            {content}
        </div>
    );
}