export const CHANGE_SEARCHSTATE = 'CHANGE_SEARCHSTATE';
export const SEARCH_MODE_HOTELS_ONLY = 'SEARCH_MODE_HOTELS_ONLY';
export const SEARCH_MODE_PACKAGE = 'SEARCH_MODE_PACKAGE'
export const CHANGE_FLEXDATE = 'CHANGE_FLEXDATE';

const today = new Date();
const start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
const end = new Date(today.getFullYear(), today.getMonth() + 2, 0);

export const defaultState ={
    theme: '',
    g1s: [],
    g2s: [],
    g3s: [],
    departureAirports: [],
    departureDates: {
        startDate: start,
        endDate: end
    },
    rooms: [{id: 0, adults: 2, children: 0, allowedChildren: [0, 1, 2, 3, 4]}],
    durations: ['7'],
    profile: 1,
    changed: false,
    fetchFacets: true,
    searchMode: SEARCH_MODE_PACKAGE,
    dateIsFlexible: false
};
const baseAllowedChildren = [0,1,2,3,4,5];

export default function SearchToolReducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_SEARCHSTATE:
            return Object.assign(
                {},
                state,
                action.value,
                { 
                    rooms: action.value.rooms.map(room => {
                        return { 
                            id: room.id,
                            adults: room.adults,
                            children: room.children,
                            allowedChildren: baseAllowedChildren.filter(i => i + Number(room.adults) <= 6) };
                    }),
                    changed: true,
                });
        case CHANGE_FLEXDATE:
            return {
                ...state,
                ...action.value
            };
        default:
            return Object.assign({}, state, { changed: false });
    }
}