import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import FetchLookups from '../../Actions/FetchLookups';
import FetchProperties from '../../Actions/FetchProperties';
import React from 'react';
import SearchToolV2 from '../SearchToolv2';
import { useAppStore } from '../../context';
import { DestinationTypes } from '../../Actions/FetchAvailabilities';

const allAdults = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
};

export default function SearchTool() {

    const state = useSelector(state => state.SearchTool, shallowEqual);
    const filterState = useSelector(state => state.Filter, shallowEqual);
    const dispatcher = useDispatch();
    var destinationsLabels = [];
    const allAttributes = {};
    var allDepartureAirports = [];
    var allProfiles = {};

    const attributes = useSelector(state => state.Attributes, shallowEqual);
    if (!attributes.loading && !attributes.loaded) {
        FetchLookups(state, 'attributes', dispatcher);
    } else if (!attributes.error) {
        attributes.attributes.forEach(attribute => allAttributes[attribute.key] = attribute.value );
    }

    const departureAirports = useSelector(state => state.DepartureAirports, shallowEqual);
    if (!departureAirports.loading && !departureAirports.loaded) {
        FetchLookups(state, 'airports', dispatcher);
    } else if (!departureAirports.error) {
        departureAirports.airports.forEach(airport => allDepartureAirports.push({ id: airport.key, name: airport.value }));
    }

    const geographies = useSelector(state => state.Countries, shallowEqual);
    if (!geographies.loading && !geographies.loaded) {
      FetchLookups(state, 'g1s,g2s,g3s', dispatcher);
    } else if (!geographies.error) {
        Object.keys(geographies.countries).forEach(country => {
            destinationsLabels.push({ id: country, name: geographies.countries[country], type: DestinationTypes.Countries});
        });
        Object.keys(geographies.regions).forEach(region => {
            destinationsLabels.push({ id: region, name: geographies.regions[region], type: DestinationTypes.Regions });
        })
        Object.keys(geographies.resorts).forEach(resort => {
            destinationsLabels.push({ id: resort, name: geographies.resorts[resort], type: DestinationTypes.Resorts });
        })
    }

    const profiles = useSelector(state => state.Profiles, shallowEqual);
    if (!profiles.loading && !profiles.loaded) {
        FetchLookups(state, 'profiles', dispatcher);
    } else if (!profiles.error) {
        profiles.profiles.forEach(profile => {
            allProfiles[profile.key] = profile.value;
        });
    }

    const { dispatch } = useAppStore();

    const Search = function(fetchFacets) {
        FetchProperties(state, filterState, dispatcher, 0, fetchFacets, dispatch);
    }

    return (
        <SearchToolV2
            themes={allAttributes}
            destinations={destinationsLabels}
            departureAirports={allDepartureAirports}
            adults={allAdults}
            children={state.allowedChildren}
            profiles={allProfiles}
            performSearch={Search}
        />
    );
}