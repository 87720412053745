import axios from '../axios';
import { FETCH_CONTENT_START, FETCH_CONTENT_FAILURE, FETCH_CONTENT_SUCCESS } from '../Reducers/ContentReducer';

const images = [
    'https://images.pexels.com/photos/338504/pexels-photo-338504.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=150',
    'https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=150',
    'https://images.pexels.com/photos/1134176/pexels-photo-1134176.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/26139/pexels-photo-26139.jpg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/2507010/pexels-photo-2507010.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/261169/pexels-photo-261169.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/97083/pexels-photo-97083.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/261395/pexels-photo-261395.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/2736388/pexels-photo-2736388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/2506990/pexels-photo-2506990.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/1320679/pexels-photo-1320679.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/122244/pexels-photo-122244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/1488327/pexels-photo-1488327.png?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/312610/pexels-photo-312610.png?auto=compress&cs=tinysrgb&dpr=2&h=150',
    'https://images.pexels.com/photos/930196/pexels-photo-930196.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=150'
]

export default function FetchContent(properties, append, dispatch) {
    dispatch({type: FETCH_CONTENT_START});
    axios.get('/content',
    {
        params: {
            properties: properties.map(p => p.propertyReferenceID).join(','),
        },
    })
    .then(response => {
        dispatch({type: FETCH_CONTENT_SUCCESS, append, values: response.data.data.map(content => Object.assign({}, content, { mainImageURL: images[Math.floor(Math.random() * images.length)]}))});
    })
    .catch(v=> {
        dispatch({type: FETCH_CONTENT_FAILURE});
    });
}