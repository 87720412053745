import { Button, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import CalendarControl from '../CalendarControl';
import Content from './content';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Rating } from '@material-ui/lab';
import TripAdvisor from '../TripAdvisor';
import useStyles from './classes';

export default function Property(props) {
    const classes = useStyles();
    let id = 0;
    const main = [
        <Typography key={++id} className={classes.name} component="h2">{props.propertyName}</Typography>,
        <Typography key={++id} variant="subtitle1" className={classes.location}>{props.location}</Typography>
    ];
    if (props.rating > 0 || props.tripAdvisorRating > 0)
    {
        const ratings = [];
        if (props.rating > 0) {
            ratings.push(<Rating key={++id} className={classes.stars} defaultValue={props.rating} precision={0.5} readOnly size="small" />);
        }
        if (props.tripAdvisorRating > 0) {
            ratings.push(<TripAdvisor key={++id} value={props.tripAdvisorRating} />);
        }
        main.push((<div key={++id} className={classes.ratings}>{ratings}</div>))
    }
    const [price, selectPrice] = useState(props.prices[0]);
    return(
        <Card className={classes.root}>
            <div className={classes.image}>
                <CardMedia 
                    className={classes.imageEl} 
                    image={props.image} 
                    title={props.imageAlt} />
            </div>
            <div className={classes.details}>
                <div className={classes.header}>
                    <div className={classes.headerCopy}>
                        {main}
                    </div>
                    <div className={classes.calendar}>
                        <CalendarControl key={++id} 
                            price={price}
                            prices={props.prices}
                            airport={props.departureAirportId}
                            property={props.propertyReference}
                            rooms={props.rooms}
                            duration={props.duration}
                            startDate={props.departureDate}
                        />
                        <div className={classes.calendarText}>
                            Discover more dates
                        </div>
                    </div>
                </div>
                <Content {...props } price={price} selectPrice={selectPrice} />
            </div>
        </Card>
    );
}