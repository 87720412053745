import ChangeSearchState from './ChangeSearchState';
import { FetchAvailableDurations, FetchAvailableMonths, FetchAvailableDates, FetchNextDepartureDate, GeographyDestinationTypeMap } from './FetchAvailabilities';

export default function ChangeDestination(searchState, destinations, dispatch) {
    const newState = Object.assign(
        {},
        searchState,
        {
            g1s: destinations.filter(destination => destination.type === GeographyDestinationTypeMap.g1s),
            g2s: destinations.filter(destination => destination.type === GeographyDestinationTypeMap.g2s),
            g3s: destinations.filter(destination => destination.type === GeographyDestinationTypeMap.g3s),
            fetchFacets: true,
        }
    );

    FetchAvailableDurations(newState, dispatch);
    FetchAvailableMonths(newState, dispatch);
    FetchAvailableDates(newState, dispatch);
    FetchNextDepartureDate(newState, dispatch);
    ChangeSearchState(newState, dispatch);
}