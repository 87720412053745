export const FETCH_PRICEBANDS_START = 'FETCH_PRICEBANDS_START';
export const FETCH_PRICEBANDS_SUCCESS = 'FETCH_PRICEBANDS_SUCCESS';
export const FETCH_PRICEBANDS_FAILURE = 'FETCH_PRICEBANDS_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    pricebands: [],
    error: false,
};

export default function PriceBandsLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_PRICEBANDS_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_PRICEBANDS_SUCCESS:
            return Object.assign({}, state, { loading: false, loaded: true, error: false, pricebands: action.values[0].values });
        case FETCH_PRICEBANDS_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}