import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterOptionList from './list';
import FilterOptionRadio from './radio';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from './classes';

export default function FilterOption(props) {
    const classes = makeStyles();
    let id = 0;
    return(
        <div className={classes.root}>
             <Typography className={classes.header}>{props.title}</Typography>
             {props.radio ? <FilterOptionRadio key={++id} {...props} /> : <FilterOptionList key={++id} {...props} />}
        </div>
        // <Accordion
        //     id={props.id}
        //     className={classes.root}
        //     defaultExpanded
        //     TransitionProps={{ unmountOnExit: true }}
        //     elevation={0}
        // >
        //     <AccordionSummary key={++id}
        //         expandIcon={<ExpandMoreIcon />}
        //         aria-controls={props.id + '-content'}
        //         id={props.id + '-header'}
        //         className={classes.item}
        //     >
               
        //     </AccordionSummary>
        //     <AccordionDetails key={++id}
        //         className={classes.details}
        //     >
                
        //     </AccordionDetails>
        // </Accordion>
    )
}