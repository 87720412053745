import { shallowEqual, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GeographyDestinationTypeMap, DestinationTypeGeographyMap } from '../../Actions/FetchAvailabilities';
import moment from 'moment';
import useStyles from './classes';
import { isEqual } from 'lodash';

export default React.memo(function NextDepartureAlert(props) {
    const classes = useStyles();
    const [state, updateState] = useState(props.selectedDestinations);
    useEffect(() => updateState(props.selectedDestinations), [props.selectedDestinations, props.departureDates]);
    const nextDepartureDateState = useSelector(state => state.AvailabilityNextDepartureDate, shallowEqual).data;

    function handleClose() {
        let newState = state.filter(i => !isEqual(i, alert.destination));
        updateState(newState);
    }

    const alert = (() => {
        var departureEnd = props.departureDates.endDate.getTime();
        for (const selectedDestination of state) {
            const nextDepratureDatesByGeography = nextDepartureDateState[DestinationTypeGeographyMap[selectedDestination.type]]
            if (nextDepratureDatesByGeography) {
                const nextDepartureDateString = nextDepratureDatesByGeography[selectedDestination.id];
                if (nextDepartureDateString) {
                    const nextDepartureDate = moment(nextDepartureDateString);
                    if (departureEnd < nextDepartureDate.toDate().getTime()) {
                        return {
                            destination: selectedDestination,
                            nextDepartureDate
                        };
                    }
                }                
            }
        }
        return null;
    })();

    const destinationName = alert ? alert.destination.name : '';
    const formattedDate = alert ?  alert.nextDepartureDate.format('MMMM Do YYYY') : '';

    return (<Collapse className={classes.collapse} in={alert}>
       <Alert className={classes.alert} severity='info' onClose={handleClose}>
            Note that next departure date available to {destinationName} is {formattedDate}
        </Alert>
    </Collapse>)
}, isEqual);