import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        alert: {
            color: 'white',
            backgroundColor: 'transparent'
        },
        collapse: {
            position: 'inherit',
        }
    }))();
}