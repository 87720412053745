import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ChangeSearchState from '../../Actions/ChangeSearchState';
import ChangeDestination from '../../Actions/ChangeDestination';
import ChangeDepartureAirport from '../../Actions/ChangeDepartureAirport';
import ChangeDepartureDate from '../../Actions/ChangeDepartureDate';
import ChangeDuration from '../../Actions/ChangeDuration';
import DatePickerWrapper from '../DatePickerWrapper';
import Dropdown from '../Dropdown';
import Multiselect from '../Multiselect';
import NextDepartureAlert from '../NextDepartureAlert';
import React from 'react';
import Rooms from '../Rooms';
import VirtualAutocomplete from '../VirtualAutocomplete';
import { useEffect } from 'react';
import useStyles from './classes';
import { SEARCH_MODE_PACKAGE } from '../../Reducers/SearchToolReducer'

export default function SearchTool(props) {
    const state = useSelector(state => state.SearchTool, shallowEqual);
    const dispatch = useDispatch();
    const classes = useStyles();

    const availableDurations = useSelector(state => state.AvailabilityDurations, shallowEqual).data;
    const availableMonths = useSelector(state => state.AvailabilityMonths, shallowEqual).data;
    const availableDates = useSelector(state => state.AvailabilityDates, shallowEqual).data;

    const durationOptions = {
        2: { id: 2, name: 2 },
        3: { id: 3, name: 3 },
        4: { id: 4, name: 4 },
        5: { id: 5, name: 5 },
        6: { id: 6, name: 6 },
        7: { id: 7, name: 7 },
        8: { id: 8, name: 8 },
        9: { id: 9, name: 9 },
        10: { id: 10, name: 10 },
        11: { id: 11, name: 11 },
        12: { id: 12, name: 12 },
        13: { id: 13, name: 13 },
        14: { id: 14, name: 14 },
        15: { id: 15, name: 15 },
        16: { id: 16, name: 16 },
        17: { id: 17, name: 17 },
        21: { id: 21, name: 21 },
        28: { id: 28, name: 28 },
    };

    useEffect(() => {
        if (state.changed) {
            props.performSearch(state.fetchFacets);
        }
    }, [state]);

    const handleChange = function(newState) {
        ChangeSearchState(Object.assign(
            {},
            state,
            newState,
            {
                fetchFacets: true,
            }
        ), dispatch);
    };

    const handleDepartureAirportChange = function(airports) {
        ChangeDepartureAirport(state, airports, dispatch);
    };

    const handleDestinationChange = function(destinations) {
        ChangeDestination(state, destinations, dispatch);
    };

    const handleDateChange = function(val) {
        ChangeDepartureDate(state, val, dispatch);
    };

    const handleDurationChange = function(val) {
        ChangeDuration(state, val, dispatch);
    }


    const destinations = state.g1s.concat(state.g2s).concat(state.g3s);
    const destinationOptions = [...props.destinations];
    destinationOptions.sort((destinationA, destinationB) => {
        if (destinationA.type !== destinationB.type)
        {
            return destinationA.type < destinationB.type ? -1 : 1;
        }
        else
        {
            return destinationA.name < destinationB.name ? -1 : 1;
        }
    });

    const departingFromControl = state.searchMode == SEARCH_MODE_PACKAGE 
    ? (<VirtualAutocomplete 
        flex
        label='Departing From' 
        options={props.departureAirports} 
        values={state.departureAirports} 
        setValues={handleDepartureAirportChange} 
        placeholder="Select your departure airport"
    />)
    : null;

    return (
        <div className="search-tool">
            <div className="search-tool__background"></div>
            <div className="search-tool__form">
                <div className={classes.inputs}>
                    <Dropdown
                        label='Theme'
                        options={props.themes}
                        value={state.theme || -1}
                        setValue={val => handleChange({theme: val})}
                        flex
                        allowNone
                        placeholder='What type of holiday?'
                        width={200}
                    />
                    <VirtualAutocomplete
                        flex
                        label='Destinations'
                        groupBy={geography => geography.type}
                        options={destinationOptions}
                        values={destinations}
                        setValues={handleDestinationChange}
                        placeholder="Select your destination"
                    />
                    {departingFromControl}
                </div>
                <div className={`${classes.inputs} ${classes.inputsFollow}`}>
                    <DatePickerWrapper
                        label='Departure dates' 
                        value={state.departureDates}
                        availableDates={availableDates}
                        availableMonths={availableMonths}
                        setValue={handleDateChange} />
                    <Multiselect 
                        label='Duration'
                        availableOptions={availableDurations} 
                        options={durationOptions}
                        values={state.durations} 
                        setValues={handleDurationChange} 
                    />
                    <Rooms 
                        rooms={state.rooms}
                        setValues={val => handleChange({rooms: val})}
                    />
                </div>
            </div>
            <NextDepartureAlert departureDates={state.departureDates} selectedDestinations={destinations} />
        </div>
    )
}