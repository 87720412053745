import { Checkbox, Chip, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';

import ImportExportIcon from '@material-ui/icons/ImportExport';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        border: "1px solid rgba(255,255,255,0.5)",
        borderRadius: "5px",
        margin: theme.spacing(1),
        flex: '0 0 auto',
    },
    flexControl : {
        border: "1px solid rgba(255,255,255,0.5)",
        borderRadius: "5px",
        margin: theme.spacing(1),
        flex: '1 0 auto',
    },
    clear: {
        color: 'white',
    },
    inlineControl: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    inlineControlLabel: {
        fontSize: "0.7em",
        marginRight: theme.spacing(1)
    },
    inlineControlSelect: {
        fontSize: "0.7em",
        '&.Mui-focused': {
            backgroundColor: "white !important"
        },
    }
}));

export default function Dropdown(props) {
    const classes = useStyles();

    const handleChange = event => {
        if (event.target.value && event.target.value !== -1) {
            props.setValue(parseInt(event.target.value, 10));
        } else {
            props.setValue('');
        }
    }

    const options = [];
    if (Object.keys(props.options).length === 0) {
        options.push(<MenuItem  value={-1} key={-1} disabled>No options</MenuItem>);
    } else {
        if (props.allowNone) {
            options.push((<MenuItem value={-1} key={-1}>Any</MenuItem>));
        }
        Object.keys(props.options).forEach(option => {
            options.push((<MenuItem value={option} key={option}>{props.options[option]}</MenuItem>));
        });
    }

    const style = {
        maxHeight: `56px`
    };
    if (props.width) {
        style['maxWidth'] = `${props.width}px`;
    }

    return(<React.Fragment>
        {props.inline &&
            <div className={classes.inlineControl}>
                <InputLabel className={classes.inlineControlLabel}><ImportExportIcon /></InputLabel>
                <Select
                    value={props.value}
                    onChange={handleChange}
                    disableUnderline={true}
                    className={classes.inlineControlSelect}
                >
                    {options}
                </Select>
            </div>
        }
        {!props.inline && 
            <FormControl 
            className={props.flex ? classes.flexControl : classes.formControl} 
            variant="filled"
            style={style}>
            <InputLabel shrink={true}>{props.label}</InputLabel>
            <Select
                value={props.value}
                onChange={handleChange}
                disableUnderline={true}
            >
                {options}
            </Select>
        </FormControl>
        }
    </React.Fragment>

    )
}