import ChangeSearchState from './ChangeSearchState';
import { FetchAvailableDurations, FetchNextDepartureDate } from './FetchAvailabilities';

export default function ChangeDepartureDate(searchState, val, dispatch) {
    const newState = Object.assign(
        {},
        searchState,
        { 
            departureDates: {startDate: val.startDate, endDate: val.endDate},
            dateIsFlexible: val.dateIsFlexible
        }
    );
    FetchAvailableDurations(newState, dispatch);
    FetchNextDepartureDate(newState, dispatch);
    ChangeSearchState(newState, dispatch);
}
