import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        root: {
            border: "1px solid rgba(255,255,255,0.5)",
            borderRadius: "5px",
            margin: theme.spacing(1),
            position: "relative",
            maxHeight: 56
        },
        card: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        button: {
            borderRadius: 0,
            textTransform: 'none',
            padding: "23px 32px 0 12px",
            fontSize: "1rem",
            color: "white",
            position: "relative"
        },
        arrow: {
            color: 'rgba(0, 0, 0, 0.54)',
            position: 'relative',
            top: -7,
            right: -26
        },
        roomButton: {
            padding: theme.spacing(1),
            display: 'inline-block',
            marginLeft: theme.spacing(1),
        },
        roomLabel: {
            height: theme.spacing(3),
            display: 'inline-block',
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
      }))();
}