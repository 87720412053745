import { makeStyles } from "@material-ui/core";

export default function useStyles() {
    return makeStyles(theme => ({
        text: {
            marginTop: 10,
        },
        input: {
            minHeight: 40,
        },
        flexroot: {
            border: "1px solid rgba(255,255,255,0.5)",
            borderRadius: "5px",
            margin: 8,
            flex: '1 0 1px',
            position: 'relative'
        },
        root: {
            border: "1px solid rgba(255,255,255,0.5)",
            borderRadius: "5px",
            margin: 8,
            flex: '0 0 auto',
            position: 'relative'
        },
        label: {
            top: -9
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: '0 0 auto',
        },
    }))();
}