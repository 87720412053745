import { Alert, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { Button, Chip, DialogTitle, IconButton, Paper, Snackbar, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import config from './../../config';
import copy from './../../clipboard';
import { makeStyles } from '@material-ui/core/styles';

export default function ApiCalls() {
  const styles = makeStyles((theme) => ({
    blade: {
      width: 600
    },
    title: {
      padding: theme.spacing(1)
    },
    timeline: {
      margin: theme.spacing(1),
      fontSize: "0.8em"
    },
    timing: {
      flex: "unset",
      width: 50
    },
    timingText: {
      fontSize: "0.8em"
    },
    paper: {
      display: "flex",
      flexDirection: "column"
    },
    url: {
      padding: theme.spacing(1),
      backgroundColor: "#f5f5f5",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    urlText: {
      flexGrow: 1
    },
    urlStatus: {

    },
    params: {
      listStyle: "none",
      margin: 0,
      padding: theme.spacing(1)
    },
    paramsList: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 18
    },
    paramKey: {
      padding: theme.spacing(1),
      paddingTop: 4,
      paddingBottom: 4,
      fontWeight: "bold",
      width: 150,
      backgroundColor: "#f5f5f5",
      border: "1px solid #f5f5f5",
      height: 18
    },
    paramValue: {
      flexGrow: 1,
      padding: theme.spacing(1),
      paddingTop: 4,
      paddingBottom: 4,
      border: "1px solid #f5f5f5",
      borderLeft: 0,
      height: 18
    },
    okLabel: {
      backgroundColor: 'green !important',
      color: 'white !important',
      fontSize: "0.8em"
    },
    sadLabel: {
      backgroundColor: 'red !important',
      color: 'white !important',
      fontSize: "0.8em"
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      margin: theme.spacing(1),
      marginTop: 0
    },
    action: {
      backgroundColor: "#f5f5f5 !important",
      textTransform: "none !important",
      fontSize: "0.8em",
      marginRight: theme.spacing(1)
    },
    expandButton: {
      backgroundColor: "#fdc530 !important",
      textTransform: "none !important",
      fontSize: "0.8em",
      marginRight: theme.spacing(1),
      marginTop: 4
    }
  }))();
  const state = useSelector(state => state.Logs, shallowEqual);
  let id = 0;
  const calls = [...state.calls].reverse();
  const [controlState, updateControlState] = useState({
    snack: false,
    message: null,
    severity: "info",
    expanded: calls.map(c => false)
  });

  function copyUrlToClipboard(request) {
    const url = formatUrl(request.url, request.params);
    try {
      copy(url);
      updateControlState({
        ...controlState,
        snack: true,
        message: 'The request URL has been copied to the clipboard',
        severity: "success"
      });
    } catch {
      updateControlState({
        ...controlState,
        snack: true,
        message: 'Unable to access your clipboard',
        severity: "error"
      });
    }
  }

  function copyResponseToClipboard(request) {
    const json = JSON.stringify(request.meta.response, null, 1);
    try {
      copy(json);
      updateControlState({
        snack: true,
        message: 'The response JSON has been copied to the clipboard',
        severity: "success"
      });
    } catch {
      updateControlState({
        snack: true,
        message: 'Unable to access your clipboard',
        severity: "error"
      });
    }
  }

  function viewUrl(request) {
    const url = formatUrl(request.url, request.params);
    window.open(url, "_blank");
  }

  function closeSnackbar() {
    updateControlState({
      ...controlState,
      snack: false
    });
  }

  function toggleParameterExpansion(index, expanded) {
    const elements = [...controlState.expanded];
    elements[index] = expanded;
    updateControlState({
      ...controlState,
      expanded: elements
    });
  }

  function RequestParams(request, requestIndex) {
    const expanded = controlState.expanded[requestIndex];
    const keys = Object.keys(request.params);
    let params = keys.map(p => (
      <li className={styles.paramsList} key={++id}>
        <div className={styles.paramKey}>{p}</div>
        <div className={styles.paramValue}>{request.params[p]}</div>
      </li>
    ));
    if (!expanded && params.length > 4) {
      params = params.slice(0, 4);
      if (params.length < keys.length) {
        params.push((
          <li className={styles.paramsList} key={++id}>
            <Button className={styles.expandButton}
              onClick={()=>toggleParameterExpansion(requestIndex, true)}>Show more</Button>
          </li>
        ));
      }
    } else if (params.length > 4) {
      params.push((
        <li className={styles.paramsList} key={++id}>
          <Button className={styles.expandButton}
              onClick={()=>toggleParameterExpansion(requestIndex, false)}>Show less</Button>
        </li>
      ));
    }

    return params;
  }

  let index = 0;

  return (
    <div className={styles.blade}>
      <DialogTitle className={styles.title}>Recent API calls</DialogTitle>
      <Timeline className={styles.timeline}>
        {calls.reverse().map(c => (
          <TimelineItem key={++id}>
            <TimelineOppositeContent className={styles.timing}>
              <Typography color="textSecondary" className={styles.timingText}>{c.meta.requestCompletedAt-c.meta.requestStartedAt}ms</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary" />
              <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={1} className={styles.paper}>
                <div className={styles.url}>
                  <div className={styles.urlText}>{c.url}</div>
                  <Chip label={c.meta.status} size="small" className={`${styles.urlStatus} ${(c.meta.status>=200 && c.meta.status < 400 ? styles.okLabel : styles.sadLabel)}`} />
                </div>
                <ul className={styles.params}>
                  {RequestParams(c, index++)}
                </ul>
                <div className={styles.actions}>
                  <Button 
                    size="small" 
                    className={styles.action}
                    onClick={()=>viewUrl(c)}>View request URL</Button>
                  <Button 
                    size="small" 
                    className={styles.action}
                    onClick={()=>copyUrlToClipboard(c)}>Copy request URL</Button>
                  {
                    !!c.meta.response && <Button 
                      size="small" 
                      className={styles.action}
                      onClick={()=>copyResponseToClipboard(c)}>Copy response JSON</Button>
                  }
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Snackbar open={controlState.snack} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={controlState.severity}>
          {controlState.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

function formatUrl(op, payload) {
  const query = [];
  for (let key of Object.keys(payload)) {
    if (payload[key]) {
      query.push(`${key}=${payload[key]}`);
    }
  }
  let baseUrl = config.BASE_URL;
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substr(0, baseUrl.length - 1);
  }
  return `${baseUrl}${op}?${query.join('&')}`;
}