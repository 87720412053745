import { FETCH_MOREPROPERTIES_START } from "../Reducers/PropertyReducer";
import FetchProperties from "./FetchProperties";

export default function LoadMoreProperties(searchToolState, filterState, properties, dispatcher) {
    dispatcher({type: FETCH_MOREPROPERTIES_START})
    let minRank = 0;
    if (properties.length > 0) {
        minRank = Math.max.apply(Math, properties.map(property => property.rank)) + 1;
    }

    FetchProperties(searchToolState, filterState, dispatcher, minRank, false);
}