import { Button, Card, FormControl, InputLabel, Popover } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import Room from './room';
import useStyles from './classes';

export default function Rooms(props) {
    const classes = useStyles();
    const fewerThanMaxRooms = props.rooms.length < 3;
    const handleChange = (id, vals) => {
        props.setValues(props.rooms.map(room => {
            if (room.id === id) {
                return Object.assign({}, room, vals);
            } else {
                return room;
            }
        }));
    }
    const handleDelete = id => {
        const newRooms = [];
        var i;
        var newid = 0;
        for (i = 0; i < props.rooms.length; i++) {
            if (props.rooms[i].id !== id) {
                newRooms.push(
                    Object.assign(
                        {},
                        props.rooms[i],
                        { id: newid }
                    )
                );
                newid++;
            }
        }
        props.setValues(newRooms);
    }
    const handleAdd = () => {
        const newRooms = props.rooms.map(r => r);
        newRooms.push({
            id: newRooms[newRooms.length - 1].id + 1,
            adults: 2,
            children: 0,
        });
        props.setValues(newRooms);
    }
    const popupContent = [];
    popupContent.push(props.rooms.map(room => 
        <Room 
            handleChange={(a, b) => handleChange(a, b)}
            handleDelete={(a, b) => handleDelete(a, b)}
            id={room.id}
            key={room.id}
            adults={room.adults}
            children={room.children}
            allowedChildren={room.allowedChildren}
            close={props.rooms.length>1}
        />
    ));
    if (fewerThanMaxRooms) {
        popupContent.push(
            <Button key={999}
                onClick={() => handleAdd()}
            >
                Add More
            </Button>
        );
    }

    const formattedPassengers = () => {
        const sum = (sum, num) => sum += num;
        
        let passengers = [];

        let adults = props.rooms.map(room => parseInt(room.adults)).reduce(sum);
        passengers.push(adults + ' adult' + (adults > 1 ? 's' : ''));

        let children = props.rooms.map(room => parseInt(room.children)).reduce(sum);
        if (children > 0) 
            passengers.push(children + (children > 1 ? ' children': ' child'));

        passengers.push(props.rooms.length + ' room' + (props.rooms.length > 1 ? 's' : ''));

        return passengers.join(', ');
    }

    return (
        <PopupState variant='popover'>
        { popupState => {
                return (
                    <div className={classes.root}>
                        <FormControl variant="filled">
                            <InputLabel shrink={true}>Passengers</InputLabel>
                            <Button 
                                className={classes.button}
                                {...bindTrigger(popupState)}
                            >
                                {formattedPassengers()}
                                <ArrowDropDownIcon className={classes.arrow} />
                            </Button>
                        </FormControl>

                        <Popover
                            {...bindPopover(popupState)}
                        >
                            <Card className={classes.card}>
                                {popupContent}
                            </Card>
                        </Popover>
                    </div>
                );
            }
        }
        </PopupState>
    );
}