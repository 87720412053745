const local = {
  BASE_URL: 'http://localhost:5000/'
};

const development = {
  BASE_URL: 'https://dealfinder-dev.ivector.co.uk/'
};

const test = {
  // BASE_URL: 'https://dealfinder-test.ivector.co.uk/'
  BASE_URL: 'https://api.dealfinder.io/'
};

const staging = {
  BASE_URL: 'https://dealfinder.stg.thomascook.io/'
};

const production = {
  BASE_URL: 'https://dealfinder.ivector.thomascook.io/'
};

let config;
switch (process.env.REACT_APP_ENVIRONMENT)
{
  case 'production':
      config = production;
      break;
  case 'staging':
      config = staging;
      break;
  case 'test':
      config = test;
      break;
  case 'local':
      config = local;
      break;
  default:
      config = development;
      break;
}


export default {
  USERNAME: 'supershift',
  PASSWORD: 'gTr7RcqzX3nPet83',
  ...config
};