import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() {
  return makeStyles(theme => ({
    root: {
      border: "1px solid rgba(255,255,255,0.5)",
      borderRadius: "5px",
      margin: theme.spacing(1),
      position: "relative",
      maxHeight: 56
    },
    button: {
        borderRadius: 0,
        textTransform: 'none',
        padding: "23px 32px 0 12px",
        fontSize: "1rem",
        color: "white",
        position: "relative"
    },
    buttonSelected: {
      backgroundColor: "#fdc530"
    },
    card: {
        padding: theme.spacing(2),
        minWidth: 200,
        maxWidth: 341,
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    months: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      border: "1px solid rgba(0,0,0,0.23)",
      borderRadius: "5px"
    },
    month: {
      flexBasis: "33%",
      border: "0px !important"
    },
    monthSelected: {
      flexBasis: "33%",
      border: "0px !important",
      backgroundColor: "#fdc530"
    },
    monthSelector: {
      maxWidth: 310
    },
    monthHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: "center",
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1)
    },
    monthHeaderYear: {
      flexGrow: 1,
      textAlign: 'center'
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.54)',
        position: 'relative',
        top: -7,
        right: -23,
        width: 16,
        height: 16
    }
  }))();
}