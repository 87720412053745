export const FETCH_AIRPORT_START = 'FETCH_AIRPORT_START';
export const FETCH_AIRPORT_SUCCESS = 'FETCH_AIRPORT_SUCCESS';
export const FETCH_AIRPORT_FAILURE = 'FETCH_AIRPORT_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    airports: [],
    error: false,
};

export default function AirportLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_AIRPORT_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_AIRPORT_SUCCESS:
            return Object.assign({}, state, { loading: false, loaded: true, error: false, airports: action.values[0].values });
        case FETCH_AIRPORT_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}