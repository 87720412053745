import AvailabilityReducerFactory from './AvailabilityReducerFactory';

export const FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_START = 'FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_START';
export const FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_SUCCESS = 'FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_SUCCESS';
export const FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_FAILURE = 'FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_FAILURE';
export const FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_WARNING = 'FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_WARNING';
export const AVAILABILITY_NEXT_DEPARTURE_DATE_RESET = 'AVAILABILITY_NEXT_DEPARTURE_DATE_RESET';

export const actions = { 
    reset: AVAILABILITY_NEXT_DEPARTURE_DATE_RESET,
    start: FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_START,
    success: FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_SUCCESS,
    warning: FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_WARNING,
    failure: FETCH_AVAILABILITY_NEXT_DEPARTURE_DATE_FAILURE
};

export default AvailabilityReducerFactory(actions, [], { success: d => d });