import AirportLookupsReducer from "./AirportLookupsReducer";
import AttributeLookupsReducer from "./AttributeLookupsReducer";
import AvailabilityDatesReducer from "./AvailabilityDatesReducer";
import AvailabilityDurationsReducer from "./AvailabilityDurationsReducer";
import AvailabilityMonthsReducer from "./AvailabilityMonthsReducer";
import AvailabilityNextDepartureDateReducer from "./AvailabilityNextDepartureDateReducer";
import ContentReducer from "./ContentReducer";
import CountryLookupsReducer from "./CountryLookupsReducer";
import DepartureAirportLookupsReducer from './DepartureAirportLookupsReducer';
import FacetReducer from "./FacetReducer";
import FacilityLookupsReducer from "./FacilityLookupsReducer";
import FilterReducer from "./FilterReducer";
import LogReducer from "./LogReducer";
import MealBasisLookupsReducer from "./MealBasisLookupsReducer";
import PriceBandsLookupsReducer from "./PriceBandsLookupsReducer";
import ProfileLookupsReducer from "./ProfileReducer";
import PropertyReducer from "./PropertyReducer";
import SearchToolReducer from "./SearchToolReducer";
import { combineReducers } from "redux";

export const RootReducer = combineReducers({
    Properties: PropertyReducer,
    SearchTool: SearchToolReducer,
    Airports: AirportLookupsReducer,
    DepartureAirports: DepartureAirportLookupsReducer,
    Countries: CountryLookupsReducer,
    MealBases: MealBasisLookupsReducer,
    Attributes: AttributeLookupsReducer,
    Facilities: FacilityLookupsReducer,
    PriceBands: PriceBandsLookupsReducer,
    AvailabilityMonths: AvailabilityMonthsReducer,
    AvailabilityDates: AvailabilityDatesReducer,
    AvailabilityDurations: AvailabilityDurationsReducer,
    AvailabilityNextDepartureDate: AvailabilityNextDepartureDateReducer,
    Filter: FilterReducer,
    Facet: FacetReducer,
    Profiles: ProfileLookupsReducer,
    Content: ContentReducer,
    Logs: LogReducer
});