import { AppContext, useAppStore } from '../../context';
import { Collapse, Container, Drawer, IconButton, LinearProgress } from "@material-ui/core";
import React, { useState } from 'react';

import ApiCalls from '../ApiCalls/ApiCalls';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import useStyles from './classes';

export default function Header() {
    const {state, dispatch} = useAppStore();
    const handleDevModeChange = (event) => {
        var action = {
            type: ""
        };
        if (event.target.checked) {
            action.type = "enableDevelopmentMode";
        } else {
            action.type = "disableDevelopmentMode";
        }
        dispatch(action);
    };
    const classes = useStyles();
    const [localState, updateLocalState] = useState({
        apiCallsOpen: false
    });

    function toggleApiCalls(open) {
        updateLocalState({
            apiCallsOpen: open
        });
    }
    return (
        <Container className={classes.container}>
            <div className={classes.header}>
                    <img src="/DealFinderLogo_481x80.png" className={classes.headerImage} />
                    <div className={classes.headerText}>
                        <FormGroup row>
                            <FormControlLabel control={<Switch checked={state.developmentMode} onChange={handleDevModeChange} name="developmentMode" />} label="Development mode" />
                        </FormGroup>
                        <IconButton className={classes.headerIcon} onClick={()=>toggleApiCalls(true)}>
                            <DeveloperModeIcon />
                        </IconButton>
                        <Drawer anchor="right" open={localState.apiCallsOpen} onClose={()=>toggleApiCalls(false)}>
                            <ApiCalls />
                        </Drawer>
                    </div>
            </div>
        </Container>
    );
}