export const FETCH_DEPARTUREAIRPORT_START = 'FETCH_DEPARTUREAIRPORT_START';
export const FETCH_DEPARTUREAIRPORT_SUCCESS = 'FETCH_DEPARTUREAIRPORT_SUCCESS';
export const FETCH_DEPARTUREAIRPORT_FAILURE = 'FETCH_DEPARTUREAIRPORT_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    airports: [],
    error: false,
};

export default function DepartureAirportLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_DEPARTUREAIRPORT_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_DEPARTUREAIRPORT_SUCCESS:
            return Object.assign({}, state, { loading: false, loaded: true, error: false, airports: action.values[0].values });
        case FETCH_DEPARTUREAIRPORT_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}