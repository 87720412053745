import { Radio, RadioGroup, Typography } from '@material-ui/core';

import React from 'react';
import useStyles from './classes';

export default function FilterOptionRadio(props) {
    const classes = useStyles();
    const handleChange = (event, value) => {
        if (value !== props.value) {
            props.setValues(value);
        }
    };
    let id = 0;
    return (
        <RadioGroup className={classes.list} aria-label={props.label} value={props.values} onChange={handleChange}>
            {
                props.options.map(option => {
                    return (<div className={classes.radio} key={++id}>
                        <Radio className={classes.check} value={option.id} size="small" />
                        <div className={classes.text}><Typography className={classes.itemText} component='span'>{option.value}</Typography></div>
                        <div className={classes.check}><Typography className={classes.itemText}>{(!!option.facet ? '(' + option.facet.toLocaleString() + ')' : '')}</Typography></div>
                    </div>);
                })
            }
        </RadioGroup>
    );
}