export const FETCH_FACETS_START = 'FETCH_FACETS_START';
export const FETCH_FACETS_SUCCESS = 'FETCH_FACETS_SUCCESS';
export const FETCH_FACETS_WARNING = 'FETCH_FACETS_WARNING';
export const FETCH_FACETS_FAILURE = 'FETCH_FACETS_FAILURE';
export const FACETS_CLEAR = 'FACETS_CLEAR';

const defaultState = {
    loading: false,
    loaded: false,
    facilities: [],
    ratings: [],
    tripAdvisorRatings: [],
    totalProperties: 0,
    filteredProperties: 0,
    geographies: [],
    mealBases: [],
    priceBands: []
};

export default function FacetReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCH_FACETS_START:
            return Object.assign({}, state, {loaded: false, loading: true});
        case FETCH_FACETS_SUCCESS:
            const facilities = Object.keys(action.values.facilities).map(key => {return { id: key, value: action.values.facilities[key]}});
            const ratings = Object.keys(action.values.ratings).map(key => { return { id: key, value: action.values.ratings[key]}});
            const mealBases = Object.keys(action.values.mealBases).map(key => {return { id: key, value: action.values.mealBases[key]}});
            const priceBands = Object.keys(action.values.priceBands).map(key => {return { id: key, value: action.values.priceBands[key]}});
            const tripAdvisorRatings = Object.keys(action.values.tripAdvisorRatings).map(key => { return { id: key, value: action.values.tripAdvisorRatings[key]}});
            let geographies = [];
            if (action.values.geographyLevel1s && Object.keys(action.values.geographyLevel1s).length) {
                geographies = Object.keys(action.values.geographyLevel1s).map(key => { return { id: key, type: 1, value: action.values.geographyLevel1s[key]}});
            } else if (action.values.geographyLevel2s && Object.keys(action.values.geographyLevel2s).length) {
                geographies = Object.keys(action.values.geographyLevel2s).map(key => { return { id: key, type: 2, value: action.values.geographyLevel2s[key]}});
            } else if (action.values.geographyLevel3s && Object.keys(action.values.geographyLevel3s).length) {
                geographies = Object.keys(action.values.geographyLevel3s).map(key => { return { id: key, type: 3, value: action.values.geographyLevel3s[key]}});
            }
            geographies.sort(sort);
            return { 
              facilities, 
              ratings,
              mealBases,
              priceBands, 
              tripAdvisorRatings, 
              totalProperties: action.values.totalProperties, 
              filteredProperties: action.values.filteredProperties,
              geographies,
              loading: false, 
              loaded: true, };
        case FETCH_FACETS_WARNING:
        case FACETS_CLEAR:
        case FETCH_FACETS_FAILURE:
            return defaultState;
        default:
            return state;
    }
}

function sort(first, second) {
    if (first.value < second.value) return 1;
    if (first.value > second.value) return -1;
    return 0;
}