import { FETCH_MONTHS_OFFSET, actions as monthsActions } from '../Reducers/AvailabilityMonthsReducer';
import { toIso8601DateFormat, toIso8601YearMonthFormat } from '../Helpers/DateFormatter';

import { LOG_CALL } from '../Reducers/LogReducer';
import axios from '../axios';
import { actions as dateActions } from '../Reducers/AvailabilityDatesReducer';
import { actions as durationActions } from '../Reducers/AvailabilityDurationsReducer';
import { isHotelsOnlySearch } from '../Helpers/SearchState';
import { actions as nextDepartureDateActions } from '../Reducers/AvailabilityNextDepartureDateReducer';
import { noCache } from '../Helpers/Caching';

export function FetchAvailabilities(searchState, dispatch) {
    let promises = [ 
        FetchAvailableMonths(searchState, dispatch),
        FetchAvailableDurations(searchState, dispatch),
        FetchNextDepartureDate(searchState, dispatch),
        FetchAvailableDates(searchState, dispatch)
    ];
    return Promise.all(promises);
}

export function FetchAvailableMonths(searchState, dispatch, year) {
    const hotelsOnly = isHotelsOnlySearch(searchState);

    if ((searchState.departureAirports.length != 0 || hotelsOnly) && (searchState.g1s.length || searchState.g2s.length || searchState.g3s.length) && searchState.durations) {

        const startMonth = toIso8601YearMonthFormat(year ? new Date(year, 0, 1) : new Date());

        const params = {
            airports: mapIdsToCsv(searchState.departureAirports),
            durations: searchState.durations.join(','),
            startMonth: startMonth,
            g1s: mapIdsToCsv(searchState.g1s), 
            g2s: mapIdsToCsv(searchState.g2s), 
            g3s: mapIdsToCsv(searchState.g3s),
            offset: FETCH_MONTHS_OFFSET
        };

        return FetchAvailability("/availability_getmonths", params, monthsActions, dispatch);
    } else {
        dispatch({type: monthsActions.reset});
    } 
}

export function FetchAvailableDates(searchState, dispatch, month) {
    const hotelsOnly = isHotelsOnlySearch(searchState);

    if ((searchState.departureAirports.length || hotelsOnly) && (searchState.g1s.length || searchState.g2s.length || searchState.g3s.length) && searchState.durations) {
        let start, end;
        if (month) {
            start = month;
            end = month;
        } else {
            start = searchState.departureDates.startDate;
            end = searchState.departureDates.endDate;
        }
        
        start = new Date(start.getFullYear(), start.getMonth(), 1);
        end = new Date(end.getFullYear(), end.getMonth() + 1, 0);

        const params = {
            airports: mapIdsToCsv(searchState.departureAirports),
            durations: searchState.durations.join(','),
            start: toIso8601DateFormat(start),
            end: toIso8601DateFormat(end),
            g1s: mapIdsToCsv(searchState.g1s), 
            g2s: mapIdsToCsv(searchState.g2s), 
            g3s: mapIdsToCsv(searchState.g3s)
         };

        return FetchAvailability("/availability_getdates", params, dateActions, dispatch)
    } else {
        dispatch({type: dateActions.reset});
    } 
}

export const DestinationTypes = {
    Countries: 'Countries',
    Regions: 'Regions',
    Resorts: 'Resorts'
}

const geographyDesintationTypeMap = [
    [ 'g1s', DestinationTypes.Countries],
    [ 'g2s', DestinationTypes.Regions],
    [ 'g3s', DestinationTypes.Resorts]
];

export const DestinationTypeGeographyMap = Object.fromEntries(geographyDesintationTypeMap.map(e => [e[1], e[0]]));

export const GeographyDestinationTypeMap = Object.fromEntries(geographyDesintationTypeMap);

export function FetchNextDepartureDate(searchState, dispatch) {
    const hotelsOnly = isHotelsOnlySearch(searchState);
    
    if ((searchState.departureAirports.length != 0 || hotelsOnly) && (searchState.g1s.length || searchState.g2s.length || searchState.g3s.length)) {
        const params = {
            airports: mapIdsToCsv(searchState.departureAirports),
            g1s: mapIdsToCsv(searchState.g1s), 
            g2s: mapIdsToCsv(searchState.g2s), 
            g3s: mapIdsToCsv(searchState.g3s)
        };

        return FetchAvailability("/availability_getnextdeparturedate", params, nextDepartureDateActions, dispatch);
    } else {
        dispatch({type: nextDepartureDateActions.reset});
    } 
}

export function FetchAvailableDurations(searchState, dispatch) {
    const hotelsOnly = isHotelsOnlySearch(searchState);

    if ((searchState.departureAirports.length != 0 || hotelsOnly) && (searchState.g1s.length || searchState.g2s.length || searchState.g3s.length)) {
        const params = {
            airports: mapIdsToCsv(searchState.departureAirports),
            durations: searchState.durations.join(','),
            month: toIso8601YearMonthFormat(searchState.departureDates.startDate),
            start: toIso8601DateFormat(searchState.departureDates.startDate),
            end: toIso8601DateFormat(searchState.departureDates.endDate),
            g1s: mapIdsToCsv(searchState.g1s), 
            g2s: mapIdsToCsv(searchState.g2s), 
            g3s: mapIdsToCsv(searchState.g3s)
        };

        return FetchAvailability("/availability_getdurations", params, durationActions, dispatch);
    } else {
        dispatch({type: durationActions.reset});
    }  
}

function FetchAvailability(path, params, actions, dispatch) {

    if (noCache()) {
        params.nocache = 1;
    }

    return axios.get(path,
    {
        params: params,
        timeout: 1000
    })
    .then(response => {
        dispatch({type: LOG_CALL, value: response.config});
        if (response.data.data.length === 0) {
            dispatch({type: actions.warning, value: 'No results returned for this availability request'});
        } else {
            dispatch({type: actions.success, values: response.data.data });
        }
    })
    .catch(v => {
        if (v.response && v.response.status >= 400 && v.response.status < 500) {
            dispatch({type: actions.warning, value: 'Availability request was invalid'});
        } else {
            dispatch({type: actions.failure});
        }
        dispatch({type: actions.reset});
    })
}

function mapIdsToCsv(ids) {
    return ids.map(g => g.id).join(',');
}
