import { Checkbox, Chip, FormControl, Input, InputLabel, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 20,
    },
    container: {
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '0 0 auto',
    },
    chip: {
        margin: 2,
        display: 'inline-flex',
    },
    drawerPaper: {
    },
    flexControl:{
        border: "1px solid rgba(255,255,255,0.5)",
        borderRadius: "5px",
        flex: '1 0 1px',
        margin: theme.spacing(1),
    },
    formControl:{
        border: "1px solid rgba(255,255,255,0.5)",
        borderRadius: "5px",
        flex: '0 0 auto',
        margin: theme.spacing(1),
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    input: {
        minHeight: 50,
    }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

export default function Multiselect(props) {
    const classes = useStyles();
    const handleChange = event => {
        props.setValues(event.target.value.filter(v => v));
    };
    const items = [];

    if (props.groupBy) {
        const groupedItems = {};
        Object.keys(props.options).map(item => {
            if (!groupedItems[props.groupBy(props.options[item])]) {
                groupedItems[props.groupBy(props.options[item])] = [];
            }
            groupedItems[props.groupBy(props.options[item])].push(props.options[item]);
        })
        Object.keys(groupedItems).forEach(item => {
            items.push(<ListSubheader disabled disableSticky>{item}</ListSubheader>);
            groupedItems[item].forEach(element => {
                var text = element.name;
                var value = element.id;
                const disabled = !props.availableOptions[parseInt(value)];
                items.push(<MenuItem disabled={disabled} key={value} value={value}>
                    <Checkbox disabled={disabled} checked={props.values.indexOf(value) > -1} />
                    <ListItemText primary={text} />
                </MenuItem>);
            })
        });
    } else {
        Object.keys(props.options).forEach(item => {
            var text = props.options[item].name;
            var value = item;
            const disabled = !props.availableOptions[parseInt(value)];
            items.push(<MenuItem disabled={disabled} key={value} value={value}>
                <Checkbox disabled={disabled} checked={props.values.indexOf(value) > -1} />
                <ListItemText primary={text} />
            </MenuItem>);
        });
    }

    const style = {
        maxHeight: `56px`
    };
    if (props.width) {
        style['maxWidth'] = `${props.width}px`;
    }

    function formatRenderValue(selected) {
        let nights = selected.map(i => parseInt(i)).map(i => props.availableOptions[i] ? props.availableOptions[i].name : null).filter(v => !!v).sort().join(', ');
        return (nights ? nights : '0') + ' nights';
    }
    
    return (
        <FormControl 
            className={props.flex ? classes.flexControl : classes.formControl}
            variant="filled"
            style={style}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                multiple
                value={props.values}
                onChange={handleChange}
                MenuProps={MenuProps}
                disableUnderline={true}
                renderValue={formatRenderValue}
            >
            {items}
            </Select>
        </FormControl>
    );
}