import { Paper } from '@material-ui/core';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useAppStore } from "../../context";
import useStyles from './classes';

export default function UrlList() {
  const { state } = useAppStore();
  const classes = useStyles();
  const main = [];
  if (state.developmentMode) {
    return (
      <Paper className={classes.root} elevation={0}>
        {
          state.searchUrl &&
          <Typography variant="body1" component="div" color="textSecondary" className={classes.body1}>
            <div className={classes.type}>Search API</div>
            <div className={classes.url}><a href={state.searchUrl} target="_blank">{state.searchUrl}</a></div>
          </Typography>
        }
        {
          state.facetsUrl &&
          <Typography variant="body1" component="div"  color="textSecondary" className={classes.body1}>
            <div className={classes.type}>Facets API</div>
            <div className={classes.url}><a href={state.facetsUrl} target="_blank">{state.facetsUrl}</a></div>
          </Typography>
        }
      </Paper>
    )
  }
  
  return (
    <div></div>
  );
}