import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        root: {
          marginBottom: theme.spacing(2),
          display: 'flex',
          flexDirection: 'row',
          border: "1px solid #fdc530",
          boxShadow: "none"
        },
        flex: {
          display: 'flex',
        },
        image: {
          width: "40%",
          // height: "100%"
        },
        imageEl: {
          height: "100%",
          objectFit: "cover"
        },
        details: {
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "60%"
        },
        result: {
          flex: '1 0 auto',
          paddingTop: 4,
        },
        header: {
          padding: theme.spacing(1),
          backgroundColor: "#f5f5f5",
          width: "100%",
          display: "flex",
          flexDirection: "row"
        },
        headerCopy: {
          flexGrow: 1
        },
        calendar: {
          display: "flex",
          flexDirection: "column",
          width: 100,
          alignItems: "center",
          marginRight: theme.spacing(1)
        },
        calendarText: {
          wordWrap: "break-all",
          fontSize: "0.7em",
          textAlign: "center"
        },
        name: {
          fontSize: '1.1em',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: "bold"
        },
        location: {
          fontSize: '0.8em',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: "#333"
        },
        ratings: {
          display: "flex"
        },
        price: {
            flex: '0 0 auto',
            fontSize: '1.3em',
            paddingRight: theme.spacing(1),
            paddingTop: 4,
            paddingLeft: theme.spacing(3) - theme.spacing(1),
        },
        stars: {
          flex: '0 0 auto',
          verticalAlign: 'text-top',
          marginTop: 5,
        },
        controls: {
          flex: '0 0 auto',
          alignItems: 'right',
          paddingLeft: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        block: {
          display: 'block',
        },
        dayDate: {
          margin: 0,
          textAlign: 'right',
          fontWeight: 600,
          position: 'relative',
          top: 2,
        },
        dayPrice: {
          margin: 0,
          textAlign: 'right',
          position: 'relative',
          top: -2,
        },
        property: {
          minHeight: '150px'
        },
        tiny: {
          fontSize: '0.75em',
          color: 'lightgray'
        },
        tripDetails: {
          marginBottom: theme.spacing(3),
        },

        content: {
          display: "flex",
          flexGrow: 1
        },
        packageDetails: {
          flexGrow: 1,
          height: "100%",
          borderRight: "1px solid #f5f5f5"
        },
        cta: {
          width: 170,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end"
        },
        ctaButton: {
          margin: theme.spacing(1)
        },
        ctaButtonControl: {
          width: "100%",
          backgroundColor: "#fdc530",
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "0.9em"
        },
        fromPrice: {
          margin: theme.spacing(1),
          textAlign: "right",
          fontSize: "1.2em",
          fontWeight: "bold",
        },
        totalPrice: {
          margin: theme.spacing(1),
          marginTop: 0,
          textAlign: "right",
          fontSize: "0.9em"
        },
        fromPriceSub: {
          fontSize: "0.6em",
          fontWeight: "normal",
          color: "#444"
        },
        package: {
          padding: theme.spacing(1),
          display: "flex",
          flexDirection: "column",
          height: "100%"
        },
        packageDetailsContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        },
        packageDetailsIcon: {
          padding: theme.spacing(0.5)
        },
        icon: {
          fontSize: "0.8em"
        },
        packageDetailsInfo: {
          flexGrow: 1,
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "center",
          fontSize: "0.8em"
        },
        priceChip: {
          marginRight: theme.spacing(1)
        },
        altPriceChip: {
          marginRight: theme.spacing(1),
          backgroundColor: "#eee !important"
        },
        spacer: {
          flexGrow: 1
        }
      }))();
}