import { FETCH_AIRPORT_FAILURE, FETCH_AIRPORT_START, FETCH_AIRPORT_SUCCESS } from '../Reducers/AirportLookupsReducer';
import { FETCH_ATTRIBUTE_FAILURE, FETCH_ATTRIBUTE_START, FETCH_ATTRIBUTE_SUCCESS } from '../Reducers/AttributeLookupsReducer';
import { FETCH_COUNTRY_FAILURE, FETCH_COUNTRY_START, FETCH_COUNTRY_SUCCESS } from '../Reducers/CountryLookupsReducer';
import { FETCH_DEPARTUREAIRPORT_FAILURE, FETCH_DEPARTUREAIRPORT_START, FETCH_DEPARTUREAIRPORT_SUCCESS } from '../Reducers/DepartureAirportLookupsReducer';
import { FETCH_FACILITIES_FAILURE, FETCH_FACILITIES_START, FETCH_FACILITIES_SUCCESS } from '../Reducers/FacilityLookupsReducer';
import { FETCH_MEALBASIS_FAILURE, FETCH_MEALBASIS_START, FETCH_MEALBASIS_SUCCESS } from '../Reducers/MealBasisLookupsReducer';
import { FETCH_PRICEBANDS_FAILURE, FETCH_PRICEBANDS_START, FETCH_PRICEBANDS_SUCCESS } from '../Reducers/PriceBandsLookupsReducer';
import { FETCH_PROFILE_FAILURE, FETCH_PROFILE_START, FETCH_PROFILE_SUCCESS } from '../Reducers/ProfileReducer';
import { SEARCH_MODE_HOTELS_ONLY } from '../Reducers/SearchToolReducer';

import { LOG_CALL } from '../Reducers/LogReducer';
import axios from '../axios';

export default function FetchLookups(searchState, lookupTypes, dispatch) {

    const hotelsOnly = searchState.searchMode == SEARCH_MODE_HOTELS_ONLY;
    const searchPathBase = hotelsOnly ? "/hotel" : "";
    const types = GetTypes(lookupTypes);
    if (types) {
        dispatch({type: types.start});
        axios.get(searchPathBase + '/lookups',
        {
            params: {
                lookuptypes: lookupTypes
            },
        })
        .then(response => {
            dispatch({type: types.success, values: response.data.data});
            dispatch({type: LOG_CALL, value: response.config});
        })
        .catch(v=> {
            dispatch({type: types.fail});
        });
    }
}

function GetTypes(lookupTypes) {
    switch (lookupTypes) {
        case "allairports":
            return {
                start: FETCH_AIRPORT_START,
                fail: FETCH_AIRPORT_FAILURE,
                success: FETCH_AIRPORT_SUCCESS,
            }
        case "airports":
            return {
                start: FETCH_DEPARTUREAIRPORT_START,
                fail: FETCH_DEPARTUREAIRPORT_FAILURE,
                success: FETCH_DEPARTUREAIRPORT_SUCCESS,
            }
        case "g1s,g2s,g3s":
            return {
                start: FETCH_COUNTRY_START,
                fail: FETCH_COUNTRY_FAILURE,
                success: FETCH_COUNTRY_SUCCESS,
            }
        case "mealbases":
            return {
                start: FETCH_MEALBASIS_START,
                fail: FETCH_MEALBASIS_FAILURE,
                success: FETCH_MEALBASIS_SUCCESS,
            }
        case "attributes":
            return {
                start: FETCH_ATTRIBUTE_START,
                fail: FETCH_ATTRIBUTE_FAILURE,
                success: FETCH_ATTRIBUTE_SUCCESS,
            }
        case "facilities":
            return {
                start: FETCH_FACILITIES_START,
                fail: FETCH_FACILITIES_FAILURE,
                success: FETCH_FACILITIES_SUCCESS,
            }
        case "profiles":
            return {
                start: FETCH_PROFILE_START,
                fail: FETCH_PROFILE_FAILURE,
                success: FETCH_PROFILE_SUCCESS,
            }
        case "pricebands":
          return {
            start: FETCH_PRICEBANDS_START,
            fail: FETCH_PRICEBANDS_FAILURE,
            success: FETCH_PRICEBANDS_SUCCESS,
          }
        default:
            return false;
    }
}