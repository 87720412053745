import { Button, Collapse, Typography } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Alert } from '@material-ui/lab';
import ChangeSearchState from '../Actions/ChangeSearchState';
import CloseWarning from '../Actions/CloseWarning';
import Dropdown from './Dropdown';
import FetchContent from '../Actions/FetchContent';
import FetchLookups from '../Actions/FetchLookups';
import LoadMoreProperties from '../Actions/LoadMoreProperties';
import Property from './Property';
import PropertySkeleton from './PropertySkeleton';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SEARCH_MODE_HOTELS_ONLY } from '../Reducers/SearchToolReducer';

const useStyles = makeStyles(theme => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      width: "100%",
    },
    container: {
      backgroundColor: theme.palette.background.default,
    },
    filter: {
      padding: theme.spacing(2),
      flex: '1 0 auto',
    },
    toolbar: theme.mixins.toolbar,
    flex: {
      display: 'flex',
    },
    button: {
      margin: '0 auto',
      display: 'block',
      marginBottom: theme.spacing(2),
    },
    header: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.6,
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    headerText: {
      flexGrow: 1
    },
    headerControls: {
      
    }
  }));

export default function PropertyResults() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state.Properties, shallowEqual);
    const searchState = useSelector(state => state.SearchTool, shallowEqual);
    const filterState = useSelector(state => state.Filter, shallowEqual);
    const facets = useSelector(state => state.Facet, shallowEqual);
    const properties = [];
    const button = [];
    var allAirports = {};
    var mealBases = {};
    let id = 0;
  
    const airportsState = useSelector(state => state.Airports, shallowEqual);
    if (!airportsState.loading && !airportsState.loaded) {
        FetchLookups(searchState, 'allairports', dispatch);
    } else if (!airportsState.error) {
      airportsState.airports.forEach(airport => allAirports[airport.key] = airport.value);
    }
  
    const mealBasisState = useSelector(state => state.MealBases, shallowEqual);
    if (!mealBasisState.loading && !mealBasisState.loaded) {
        FetchLookups(searchState, 'mealbases', dispatch);
    } else if (!mealBasisState.error) {
      mealBasisState.mealBases.forEach(basis => mealBases[basis.key] = basis.value);
    }

    const geographies = useSelector(state => state.Countries, shallowEqual);
    if (!geographies.loading && !geographies.loaded) {
      FetchLookups(searchState, 'g1s,g2s,g3s', dispatch);
    }
    const contentState = useSelector(state => state.Content, shallowEqual);

    const hotelsOnly = searchState.searchMode == SEARCH_MODE_HOTELS_ONLY;

    if (state.loaded && state.properties.length > 0) {

      if (!contentState.loading && !contentState.loaded) {
        FetchContent(state.properties, false, dispatch);
      } else if (contentState.loaded && state.properties.length != contentState.properties.length) {
        FetchContent(state.properties.filter(p => !contentState.properties.some(q => p.propertyReferenceID === q.propertyReferenceID)), true, dispatch);
      }

      state.properties.forEach(property => 
      {
        let content = contentState.properties.filter(p => p.propertyReferenceID === property.propertyReferenceID)[0];
        const pricedMealBases = {};
        let i = 1;
        property.rooms.forEach(room => {
          if (!pricedMealBases[room.leadInMealBasisID]) {
            pricedMealBases[room.leadInMealBasisID] = { rooms: [] };
          }
            
          pricedMealBases[room.leadInMealBasisID].rooms.push({roomId: i, price: room.leadInPrice, packageReference: room.packageReference });
          

          Object.keys(room.otherPrices).forEach(key =>
        {
            if (!pricedMealBases[key]) {
              pricedMealBases[key] = { rooms: [] };
            }
              
            pricedMealBases[key].rooms.push({
              roomId: i, 
              price: room.otherPrices[key].price, 
              packageReference: room.otherPrices[key].packageReference });
          });

          i++;
        });
        const prices = Object.keys(pricedMealBases).map(key => {
          return {
            mealBasisID: key,
            rooms: pricedMealBases[key].rooms
          };
        });
        const mealBasisPrices = prices
          .filter(price => {
            const mbs = Object.keys(filterState.mealBases).map(mb => filterState.mealBases[mb]);
            if (mbs.length > 0) {
              return mbs.some(mb => Number(mb) === Number(price.mealBasisID));
            } else {
              return true;
            }
          })
          .map(price => {
            return {
              rooms: price.rooms.map(room => {
                return {
                  roomId: room.roomId,
                  price: room.price.toFixed(2),
                  packageReference: room.packageReference
                };
              }),
              mealBasisID: price.mealBasisID,
              mealBasis: mealBases[price.mealBasisID] ?? price.mealBasisID, 
            };
          }
        );

        if (!content) {
          content = {
            propertyName: '',
            geographyLevel3: '',
            geographyLevel2: '',
            airport: '',
            rating: 0,
            tripAdvisorRating: 0,
          }
        }
        // MA - Temp - Calendar does not support multiroom - so grab the first room deets for adults/children
        const location = content.geographyLevel1 + ', ' + content.geographyLevel2 + (content.geographyLevel3 === content.geographyLevel2 ? '' : ', ' + content.geographyLevel3);
        properties.push(<Property
          image={content.mainImageURL}
          imageAlt={property.propertyName}
          propertyName={content.propertyName}
          location={location}
          prices={mealBasisPrices}
          departureAirport={allAirports[property.departureAirportID]}
          departureAirportId={property.departureAirportID}
          departureDate={new Date(property.departureDate)}
          propertyReference={property.propertyReferenceID}
          rooms={searchState.rooms}
          arrivalAirport={allAirports[property.arrivalAirportID]}
          duration={property.duration}
          rating={content.rating}
          key={++id}
          departureDate={property.departureDate}
          tripAdvisorRating={content.tripAdvisorRating}
        />);
      });
      if (state.loadingMore) {
        button.push(<PropertySkeleton key={++id} />);
      }
      else if (!state.loadedAll) {
        button.push((<Button key={++id}
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          onClick={() => LoadMoreProperties(searchState, filterState, state.properties, dispatch)}><Typography>Load More</Typography></Button>));
      }
    } else if (state.loaded) {
      properties.push(<Collapse key={++id} in={!state.warningSeen}><Alert className={classes.alert} severity='warning' onClose={() => CloseWarning(dispatch)}>{state.warning}</Alert></Collapse>)
    }

    const profiles = useSelector(state => state.Profiles, shallowEqual);
    const allProfiles=[];
    profiles.profiles.forEach(p => allProfiles[p.key]=p.value);

    const handleSortChange = function(newState) {
        ChangeSearchState(Object.assign(
            {},
            searchState,
            newState,
            {
                fetchFacets: false,
            }
        ), dispatch);
    }

    const dealName = hotelsOnly ? 'hotels' : 'packages';

    return (
        <div className={classes.content}>
          <section className={classes.header}>
            <div className={classes.headerText}>
            <Typography variant="h6" component="div">{facets.loading ? 'Searching for your perfect holiday...' : 
              <span>
                {
                  (facets.totalProperties===facets.filteredProperties)
                ? <React.Fragment>Showing <strong>{facets.totalProperties.toLocaleString()}</strong> {dealName}</React.Fragment>
                  : <React.Fragment>Showing <strong>{facets.filteredProperties.toLocaleString()}</strong> of <strong>{facets.totalProperties.toLocaleString()}</strong> {dealName}</React.Fragment>
                }
              </span>}
            </Typography>
            </div>
            <div className={classes.headerControls}>
              <Dropdown
                  label='Sort:'
                  options={allProfiles}
                  value={searchState.profile}
                  setValue={val => handleSortChange({profile: val})}
                  inline={true}
              />
            </div>
          </section>
          <main className={classes.results}>
              {properties}
              {button}
          </main>
        </div>
    );
}