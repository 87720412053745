import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  TextField,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import VirtualListbox from "./virtualListbox";
import useStyles from "./classes";

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

function getOptionLabel(item) {
  if (item.name) {
    return item.name;
  }
  return item;
}

export default function VirtualAutocomplete(props) {
  const classes = useStyles();
  const handleChange = (event, v) => {
    props.setValues(v);
  };

  const style = {
    maxHeight: `56px`,
  };
  if (props.width) {
    style["maxWidth"] = `${props.width}px`;
  }

  return (
    <Autocomplete
      multiple
      id={props.id}
      value={props.values}
      className={props.flex ? classes.flexroot : classes.root}
      options={props.options}
      groupBy={props.groupBy}
      renderGroup={renderGroup}
      disableListWrap
      getOptionLabel={(n) => getOptionLabel(n)}
      getOptionSelected={(option, value) =>
        option.type === value.type && option.id === value.id
      }
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant="filled"
          label={props.label}
          placeholder={props.placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <div>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </div>
        </React.Fragment>
      )}
      onChange={handleChange}
      ListboxComponent={VirtualListbox}
      style={style}
    />
  );
}
