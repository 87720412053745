import { LinearProgress, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ChangeFilterState from '../../Actions/ChangeFilterState';
import FilterOption from '../FilterOption';
import useStyles from './classes';

export default function Filter(props) {
    const state = useSelector(state => state.Filter, shallowEqual);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (state.changed) {
            props.performSearch();
        }
    }, [state]);

    const handleChange = function(newState) {
        ChangeFilterState(Object.assign(
            {},
            state,
            newState
        ), dispatch);
    };

    const total = props.totalProperties;
    const filtered = props.filteredProperties;
    const message = (total == filtered)
      ? `Showing ${total} results`
      : `Showing ${filtered} of ${total} results`;
      let id = 0;

    const options = [];
    if (props.loading) {
        options.push(<LinearProgress color='secondary' key={++id} />);
    } else {
        options.push(
            <FilterOption key={++id}
                id='rating-filter'
                title='Rating'
                options={props.ratings}
                values={state.ratings}
                setValues={vals => handleChange({ratings: vals})}
                stars
            />
        );
        options.push(
            <FilterOption key={++id}
                id='mealbasis-filter'
                title='Meal Basis'
                options={props.mealBases}
                values={state.mealBases}
                setValues={vals => handleChange({mealBases: vals})}
            />
        );
        if (props.geographies.length > 0) {
            let title = '';
            switch (props.geographies[0].type) {
                case 1:
                    title = 'Country';
                    break;
                case 2:
                    title = 'Region';
                    break;
                case 3:
                    title = 'Resort';
                    break;
            }
            options.push(
                <FilterOption key={++id}
                    id='geography-filter'
                    title={title}
                    options={props.geographies}
                    values={state.geographies}
                    setValues={vals => handleChange({geographies: vals, geographyType: title})}
                    maxItems={8}
                    sort={true}
                />
            );
        }
        options.push(
            <FilterOption key={++id}
                id='priceband-filter'
                title='Price Per Person'
                options={props.priceBands}
                values={state.priceBands}
                setValues={vals => handleChange({priceBands: vals})}
                radio
            />
        );
        options.push(
            <FilterOption key={++id}
                id='tripadvisor-filter'
                title='TripAdvisor'
                options={props.tripAdvisorRatings}
                values={state.minTripAdvisorRating}
                setValues={vals => handleChange({minTripAdvisorRating: vals})}
                radio
            />
        );
        options.push(
            <FilterOption key={++id}
                id='facilities-filter'
                title='Facilities'
                options={props.facilities}
                values={state.facilities}
                setValues={vals => handleChange({facilities: vals})}
            />
        );
    }

    return(
        <div className={classes.container}>
            <Typography key={++id} variant="h6" className={classes.header}>Refine your search...</Typography>
            {options}
        </div>
    );
}