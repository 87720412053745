import ChangeSearchState from './ChangeSearchState';
import { FetchAvailableDates, FetchAvailableDurations, FetchAvailableMonths } from './FetchAvailabilities';

export default function ChangeDepartureAirport(searchState, airports, dispatch) {
    const newState = Object.assign(
        {},
        searchState,
        { departureAirports: airports },
        {
            fetchFacets: true,
        }
    );
    FetchAvailableDurations(newState, dispatch);
    FetchAvailableMonths(newState, dispatch);
    FetchAvailableDates(newState, dispatch);
    ChangeSearchState(newState, dispatch);
}