import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        toolbar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            display: 'block',
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        flex: {
            display: 'flex'
        },
        container: {
            flex: '0 0 auto',
            width: 250,
            paddingBottom: theme.spacing(1)
        },
        header: {
            marginBottom: theme.spacing(2)
        }
    }))();
}