import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import FetchLookups from '../../Actions/FetchLookups';
import FetchProperties from '../../Actions/FetchProperties';
import Filter from '../Filter';
import React from 'react';
import { useAppStore } from '../../context';
import { useState } from 'react';

export default function FilterContainer(props) {
    const dispatcher = useDispatch();
    let allMealBases = [];
    let allFacilities = [];
    let allRatings = [];
    let allTripAdvisorRatings = [];
    let allPriceBands = [];
    let allFilterGeographies = [];
    const mealBasisState = useSelector(state => state.MealBases, shallowEqual);
    const facilitiesState = useSelector(state => state.Facilities, shallowEqual);
    const priceBandState = useSelector(state => state.PriceBands, shallowEqual);
    const facets = useSelector(state => state.Facet, shallowEqual);
    const searchState = useSelector(state => state.SearchTool, shallowEqual);
    const filterState = useSelector(state => state.Filter, shallowEqual);
    const geographyState = useSelector(state => state.Countries, shallowEqual);

    if (!mealBasisState.loading && !mealBasisState.loaded) {
        FetchLookups(searchState, 'mealbases', dispatcher);
    } else if (!mealBasisState.error) {
        allMealBases = [];
        mealBasisState.mealBases.forEach(basis => {
          const matchingFacets = facets.mealBases.filter(facet => facet.id === basis.key);
          if (matchingFacets.length > 0) {
            allMealBases.push({id: basis.key, value: basis.value, facet: matchingFacets[0].value})
          } else {
            allMealBases.push({id: basis.key, value: basis.value, facet: 0})
          }
        });
    }

    if (!priceBandState.loading && !priceBandState.loaded) {
        FetchLookups(searchState, 'pricebands', dispatcher);
    } else if (!priceBandState.error) {
        allPriceBands = [{
          id: '', 
          value: 'All'
        }];
        var sortedBands = [...priceBandState.pricebands];
        sortedBands.sort((bandA, bandB) => {
          const amountA = parseInt(bandA.value.split(' - ')[0].replace(/£|\+|,/gi, ''));
          const amountB = parseInt(bandB.value.split(' - ')[0].replace(/£|\+|,/gi, ''));
          return amountA > amountB ? 1 : -1;
        });
        sortedBands.forEach(basis => {
          const matchingFacets = facets.priceBands.filter(facet => facet.id === basis.key);
          if (matchingFacets.length > 0) {
            allPriceBands.push({id: basis.key, value: basis.value, facet: matchingFacets[0].value})
          } else {
            allPriceBands.push({id: basis.key, value: basis.value, facet: 0})
          }
        });
    }

    if (!facilitiesState.loading && !facilitiesState.loaded) {
        FetchLookups(searchState, 'facilities', dispatcher);
    } else if (!facilitiesState.error) {
        allFacilities = [];
        facilitiesState.facilities.forEach(basis => {
            const matchingFacets = facets.facilities.filter(facet => facet.id === basis.key);
            if (matchingFacets.length > 0) {
                allFacilities.push({id: basis.key, value: basis.value, facet: matchingFacets[0].value});
            } else {
              allFacilities.push({id: basis.key, value: basis.value, facet: 0})
            }
        });
        allFacilities.sort((facilityA, facilityB) => {
            const sort = facilityA.value > facilityB.value;
            return sort ? 1 : -1;
        });
    }

    if (facets.loaded && facets.ratings) {
        facets.ratings.forEach(rating => 
          allRatings.push({id: rating.id, value: rating.id === '0' ? 'Unspecified' : rating.id, facet: rating.value})
        );
        allRatings.sort((ratingA, ratingB) => {
            const sort = ratingA.id > ratingB.id;
            return sort ? -1 : 1;
        });
        facets.tripAdvisorRatings.filter(rating => rating.id <= 5 && rating.id >= 3).forEach(rating => 
          allTripAdvisorRatings.push({id: rating.id, value: rating.id === '5' ? rating.id : rating.id + ' or above', facet: rating.value})
        );
        allTripAdvisorRatings.sort((ratingA, ratingB) => {
            const sort = ratingA.id === '0' ? true : ratingA.id > ratingB.id;
            return sort ? -1 : 1;
        });
        
        allTripAdvisorRatings = [{
          id: '0', 
          value: 'All'
        }].concat(allTripAdvisorRatings);
    }
    
    if (!geographyState.loading && !geographyState.loaded) {
      FetchLookups(searchState, 'g1s,g2s,g3s', dispatch);
    }
    else if (!geographyState.error) {
      if (facets.loaded && facets.geographies) {
        facets.geographies.forEach(geography => {
          let name = '';
          switch (geography.type) {
            case 1:
              name = geographyState.countries[geography.id];
              break;
            case 2:
              name = geographyState.regions[geography.id];
              break;
            case 3:
              name = geographyState.resorts[geography.id];
              break;
          }
          allFilterGeographies.push({id : geography.id, value: name, type: geography.type, facet: geography.value});
        })
      }
    }

    const { dispatch } = useAppStore();

    const Search = function() {
        FetchProperties(searchState, filterState, dispatcher, undefined, true, dispatch);
    }

    return(
        <Filter
            geographies={allFilterGeographies}
            mealBases={allMealBases}
            facilities={allFacilities}
            ratings={allRatings}
            priceBands={allPriceBands}
            tripAdvisorRatings={allTripAdvisorRatings}
            performSearch={Search}
            totalProperties={facets.totalProperties}
            filteredProperties={facets.filteredProperties}
            loading={facets.loading}
        />
    );
}