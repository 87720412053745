import { Button, Chip, DialogActions, DialogTitle, IconButton, Popover, Typography, makeStyles } from '@material-ui/core';
import { Calendar, Day } from '@material-ui/pickers';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { LOG_CALL } from '../Reducers/LogReducer';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import TodayIcon from '@material-ui/icons/Today';
import axios from '../axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SEARCH_MODE_HOTELS_ONLY } from '../Reducers/SearchToolReducer';

const useStyles= makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  image: {
    flex: '1 0 auto',
  },
  content: {
    flex: '1 0 auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  name: {
    flex: '0.05 0 auto',
    fontSize: '1.5em',
  },
  price: {
      flex: '0 0 auto',
      fontSize: '1.3em',
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
  },
  stars: {
    flex: '1 0 auto',
    verticalAlign: 'text-top',
  },
  controls: {
    flex: '0 0 auto',
    alignItems: 'right',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  calendar: {
    padding: theme.spacing(1),
  },
  modal: {
  },
  calendarTitle: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',    
  },
  block: {
    display: 'block',
    justifyContent: 'right',
    textAlign: 'right',
  },
  dayDate: {
    margin: 0,
    fontWeight: 600,
    position: 'relative',
    top: 2,
    fontSize: '1.2em',
    textAlign:'center'
  },
  dayPrice: {
    margin: 0,
    textAlign: 'right',
    position: 'relative',
    top: -2,
    fontSize: '0.85em',
    color: "#222"
  },
  hiddenText: {
    color: '#ffffff',
  },
  packageDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: theme.spacing(1)
  },
  packageDetailsIcon: {
    padding: theme.spacing(0.5)
  },
  icon: {
    fontSize: "0.8em"
  },
  packageDetailsInfo: {
    flexGrow: 1,
    // display: "flex",
    // justifyContent: "flex-start",
    // alignItems: "center",
    fontSize: "0.8em"
  },
  priceChip: {
    marginRight: theme.spacing(1)
  },
  altPriceChip: {
    marginRight: theme.spacing(1),
    backgroundColor: "#eee !important"
  },
  spacer: {
    flexGrow: 1
  },
  dialogTitle: {
    padding: 0,
    margin: theme.spacing(1)
  }
}));

export default function CalendarControl(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [price, selectPriceState] = useState(props.price);
  const [selectedDate, handleDateChange] = useState(new Date(props.startDate));
  const [monthPrices, setMonthPrices] = useState({});
  const searchState = useSelector(state => state.SearchTool, shallowEqual);
  let id = 0;

  const handleMonthPrices = (month, prices, mealBasisID) => {
    const copy = {};
    Object.assign(copy, monthPrices);
    copy[`${month}_${mealBasisID}`] = prices;
    setMonthPrices(copy);
  }

  function selectPrice(newPrice) {
    selectPriceState(newPrice);
    FetchMonth(
      dispatch,
      selectedDate,
      newPrice.mealBasisID,
      props.airport,
      props.property,
      props.rooms,
      props.duration,
      monthPrices,
      handleMonthPrices,
      searchState);
  }

  const pax = props.rooms[0].adults + props.rooms[0].children;
  
  return (<PopupState variant="popover">
    { popupState => {
      const buttonProps = {...bindTrigger(popupState)};
      const newButtonProps = Object.assign(
        {},
        buttonProps,
        {
          onClick: (a, b) => {
            if (Object.keys(monthPrices).length === 0) {
              FetchMonth(
                dispatch,
                selectedDate,
                price.mealBasisID,
                props.airport,
                props.property,
                props.rooms,
                props.duration,
                monthPrices,
                handleMonthPrices,
                searchState);
            }
            buttonProps.onClick(a, b);
          }
        })
      return (<div>
        <IconButton className={classes.calendar} {...newButtonProps}>
          <TodayIcon />
        </IconButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.modal}
        >
          <DialogTitle className={classes.dialogTitle}>Discover more dates</DialogTitle>
          <div className={classes.packageDetailsContainer}>
                <div className={classes.packageDetailsIcon}>
                    <RestaurantIcon className={classes.icon} />
                </div>
                <div className={classes.packageDetailsInfo}>
                    {props.prices.map(p =>
                        <Chip 
                            key={++id} 
                            className={(p.mealBasisID===price.mealBasisID ? classes.priceChip : classes.altPriceChip)}
                            onClick={()=>selectPrice(p)}
                            label={p.mealBasis}></Chip>
                    )}
                </div>
            </div>
          <Calendar 
            orientation='landscape'
            disablePast
            renderDay={(a, b, c, d) => renderDay(a, c, getPrice(a, monthPrices, pax, price.mealBasisID))} 
            date={selectedDate} 
            onChange={date => handleDateChange(date)} 
            onMonthChange={date => FetchMonth(
              dispatch,
              date,
              price.mealBasisID,
              props.airport,
              props.property,
              props.rooms,
              props.duration,
              monthPrices,
              handleMonthPrices,
              searchState)} 
            />
        </Popover>
      </div>);
    }}
  </PopupState>);
}

function renderDay(day, currentMonth, price) {
  const today = new Date();
  today.setHours(0,0,0,0);
  return (<DayAndPrice disabled={day < today || !price} date={day.getDate()} currentMonth={currentMonth} price={price} />)
}

function getPrice(date, monthPrices, pax, mealBasisID) {
  const month = new Date(date.getFullYear(), date.getMonth());
  const day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  month.setHours(0, 0, 0, 0);
  day.setHours(0, 0, 0, 0);
  const vals = monthPrices[`${month}_${mealBasisID}`];
  if (monthPrices && vals) {
    const filter = vals.filter(val => {
      const d = new Date(val.departureDate);
      d.setHours(0, 0, 0, 0);
      return day.getTime() === d.getTime();
    })[0];
    if (filter) {
      const price = (filter.rooms[0].price / pax).toFixed(2);
      return price;
    }
  }

  return null;
}

export function DayAndPrice(props) {
  const classes = useStyles();
  return(<Day disabled={props.disabled} hidden={!props.currentMonth}>
    <span className={classes.block}>
      <div className={classes.dayDate}>{props.date}</div>
      <div className={classes.dayPrice + ' ' + (props.price > 0 ? '' : classes.hiddenText)}>{'£' + props.price}</div>
    </span>
  </Day>);
}

function FetchMonth(dispatch, date, mealBasis, airportId, propertyRef, rooms, duration, monthPrices, handleMonthPrices, searchState) {
  const today = new Date();
  const month = new Date(date.getFullYear(), date.getMonth());
  if (!monthPrices[`${month}_${mealBasis}`]) {
    const dayOfMonth = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() ? date.getDate() : 1;
    const day = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + dayOfMonth).slice(-2);
    const temp = new Date(day);
    temp.setMonth(temp.getMonth() + 1);
    temp.setDate(temp.getDate() - dayOfMonth);
    const lastDay = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + temp.getDate()).slice(-2);
    const hotelsOnly = searchState.searchMode == SEARCH_MODE_HOTELS_ONLY;
    const searchPathBase = hotelsOnly ? "/hotel" : "";

    return axios.get(searchPathBase + '/calendar',
    {
        params: {
            airport: airportId,
            property: propertyRef,
            mealBasis: mealBasis,
            start: day,
            end: lastDay,
            rooms: rooms.map(room => '(' + room.adults + ',' + room.children + ')').join(','),
            duration: duration
        }
    })
    .then(response => {
      dispatch({type: LOG_CALL, value: response.config});
      if (response.data.data.length > 0) {
        handleMonthPrices(month, response.data.data, mealBasis);
      }
    })
    .catch(v => { console.log(month, v.response);});
  }
}