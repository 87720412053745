export const FETCH_MEALBASIS_START = 'FETCH_MEALBASIS_START';
export const FETCH_MEALBASIS_SUCCESS = 'FETCH_MEALBASIS_SUCCESS';
export const FETCH_MEALBASIS_FAILURE = 'FETCH_MEALBASIS_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    mealBases: [],
    error: false,
};

export default function MealBasisLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_MEALBASIS_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_MEALBASIS_SUCCESS:
            return Object.assign({}, state, { loading: false, loaded: true, error: false, mealBases: action.values[0].values });
        case FETCH_MEALBASIS_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}