import axios from 'axios';
import config from './config';

const instance = axios.create({ 
  baseURL: config.BASE_URL,
  responseType: 'json',
  auth: {
    username: config.USERNAME,
    password: config.PASSWORD
  } 
});

instance.interceptors.request.use(x => {
  x.meta = x.meta || {};
  x.meta.requestStartedAt = new Date().getTime();
  return x;
});

instance.interceptors.response.use(x => {
  x.config.meta.requestCompletedAt = new Date().getTime();
  x.config.meta.status = x.status;
  x.config.meta.response = x.data;
  return x;
});

export default instance;