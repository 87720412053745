import React, { createContext, useContext, useReducer } from 'react';

import config from './config';

export const AppState = {
  developmentMode: false,
  searchUrl: '',
  facetsUrl: ''
};

export const AppContext = createContext(AppState);

export const AppReducer = (state, action) => {
  switch (action.type) {
    case "enableDevelopmentMode":
      state = {
        ...state,
        developmentMode: true
      };
      break;
    case "disableDevelopmentMode":
      state = {
        ...state,
        developmentMode: false
      };
      break;
    case "setSearchUrl":
      state = {
        ...state,
        searchUrl: formatUrl('search', action.payload)
      };
      break;
    case "setFacetsUrl":
      state = {
        ...state,
        facetsUrl: formatUrl('facets', action.payload)
      };
      break;
  }
  return state;
};

function formatUrl(op, payload) {
  const query = [];
  for (let key of Object.keys(payload)) {
    if (payload[key]) {
      query.push(`${key}=${payload[key]}`);
    }
  }
  return `${config.BASE_URL}${op}?${query.join('&')}`;
}

export const AppStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, AppState);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
};

export const useAppStore = () => useContext(AppContext);