export const FETCH_COUNTRY_START = 'FETCH_COUNTRY_START';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAILURE = 'FETCH_COUNTRY_FAILURE';

const defaultState ={
    loading: false,
    loaded: false,
    countries: [],
    regions: [],
    resorts: [],
    error: false,
};

export default function CountryLookupsReducer(state = defaultState, action) {
    switch(action.type) {
        case FETCH_COUNTRY_START:
            return Object.assign({}, state, { loading: true, loaded: false, });
        case FETCH_COUNTRY_SUCCESS:
            const countries = {};
            const regions = {};
            const resorts = {};
            action.values.forEach(group => {
                switch(group.group) {
                    case 'g1s':
                        group.values.forEach(value => {
                            countries[value.key] = value.value;
                        });
                        break;
                    case 'g2s':
                        group.values.forEach(value => {
                            regions[value.key] = value.value;
                        });
                        break;
                    case 'g3s':
                        group.values.forEach(value => {
                            resorts[value.key] = value.value;
                        });
                        break;
                }
            })
            return Object.assign({}, state, { loading: false, loaded: true, error: false, countries, regions, resorts });
        case FETCH_COUNTRY_FAILURE:
            return Object.assign({}, state, { loading: false, loaded: true, error: true, });
        default:
            return state;
    }
}