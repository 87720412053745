
import AvailabilityReducerFactory from './AvailabilityReducerFactory';

export const FETCH_AVAILABILITY_MONTHS_START = 'FETCH_AVAILABILITY_MONTHS_START';
export const FETCH_AVAILABILITY_MONTHS_SUCCESS = 'FETCH_AVAILABILITY_MONTHS_SUCCESS';
export const FETCH_AVAILABILITY_MONTHS_FAILURE = 'FETCH_AVAILABILITY_MONTHS_FAILURE';
export const FETCH_AVAILABILITY_MONTHS_WARNING = 'FETCH_AVAILABILITY_MONTHS_WARNING';
export const AVAILABILITY_MONTHS_RESET = 'AVAILABILITY_MONTHS_RESET';

export const actions = { reset: AVAILABILITY_MONTHS_RESET, start: FETCH_AVAILABILITY_MONTHS_START, success: FETCH_AVAILABILITY_MONTHS_SUCCESS, warning: FETCH_AVAILABILITY_MONTHS_WARNING, failure: FETCH_AVAILABILITY_MONTHS_FAILURE };

export const FETCH_MONTHS_OFFSET = 15;

const defaultData = {
    allowAll: true,
    years: null
  };

export default AvailabilityReducerFactory(actions, defaultData, { success: d => d });