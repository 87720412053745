import './styles.css';

import React from 'react';

export default function TripAdvisorRating(props) {
    const rating = [];
    const valueRounded = Math.round(props.value * 10 / 5) * 0.5;
    let id = 0;
    var i;
    for (i = 0; i < 5; i++) {
        if (i < valueRounded - 0.5) {
            rating.push(<i key={++id} className={'rating full ' + props.childClassName} />);
        } else if (i < valueRounded) {
            rating.push(<i key={++id} className={'rating half ' + props.childClassName} />);
        } else {
            rating.push(<i key={++id} className={'rating empty ' + props.childClassName} />);
        }
    }

    return(
        <span className={props.className}>
            {rating}
        </span>
    );
}