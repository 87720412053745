import { Button, List } from '@material-ui/core';

import FilterOptionItem from './item';
import React from 'react';
import makeStyles from './classes';
import { useState } from 'react';

export default function FilterOptionList(props) {
    const checked = [];
    props.values.forEach(val => checked.push(val));
    const classes = makeStyles();
    const handleToggle = value => () => {
        const newChecked = []
        const currentIndex = checked.indexOf(value);
        checked.forEach(val => newChecked.push(val));
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        props.setValues(newChecked);
    }
    const source = [...props.options];
    let options = source;
    if (options.length && props.maxItems) {
        options = options.slice(0, props.maxItems - 1);
    }
    const hasMore = options.length!==source.length;

    const [showAll, setShowAll] = useState(!hasMore);

    if (showAll) {
        options = source;
    }

    let id = 0;

    if (props.sort) {
        options.sort((a,b) => (a.value<b.value)?-1:(a.value>b.value)?1:0);
    }
    
    const ui = [
        <List
            className={classes.list}
        >
            {options.length ? options.map(option => 
                <FilterOptionItem
                    key={++id}
                    id={option.id}
                    checked={checked}
                    handleToggle={handleToggle}
                    labelId={props.title + '-' + option.id + '-label'}
                    value={option.value}
                    facet={option.facet}
                    stars={props.stars}
                    rating={props.rating}
                />
            ) : <div key={++id} />}
            {hasMore ? 
                <div className={classes.showMore} key={++id}>
                    <Button  
                        variant="contained" 
                        disableElevation={true}
                        color="secondary" 
                        size="small"
                        onClick={() => setShowAll(!showAll)}>Show {showAll ? "less" : "more"}</Button>
                </div> : <div key={++id} />}
        </List>
    ];
    return (ui);
}