import { makeStyles } from "@material-ui/core";

export default function useStyles() {
    return makeStyles(theme => ({
        root: {
            flex: '1 0 auto',
            backgroundColor: "transparent",
            margin: 0,
            marginBottom: theme.spacing(2)
        },
        item: {
            height: theme.spacing(3),
            margin: 0,
            fontSize: "0.8rem",
            '&.Mui-expanded': {
                marginBottom: 0
            }
        },
        flex: {
            display: 'flex',
        },
        check: {
            flex: '0 0 auto',
            minWidth: 0,
        },
        text: {
            flex: '1 1 auto',
            display: 'inline-flex',
            overflow: 'hidden',
            height: '24px',
        },
        list: {
            width: '100%',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        details: {
            padding: 0,
            paddingBottom: theme.spacing(1),
            marginTop: -20
        },
        header: {
            fontWeight: 'bold',
            fontSize: "0.9rem",
        },
        radio: {
            height: theme.spacing(3),
            margin: 0,
            paddingRight: theme.spacing(2),
            display: 'flex',
        },
        showMore: {
            padding: theme.spacing(2),
            paddingBottom: 0
        },
        itemText: {
            fontSize: "0.85rem",
            lineHeight: "24px"
        }
    }))();
}