import { CHANGE_SEARCHSTATE } from './SearchToolReducer';

export const CHANGE_FILTERSTATE = 'CHANGE_FILTERSTATE';

export const defaultState ={
    budgetMin: 0,
    budgetMax: 0,
    mealBases: [],
    facilities: [],
    ratings: [],
    geographies: [],
    geographyType: '',
    minTripAdvisorRating: '0',
    priceBands: "",
    changed: false,
};

export default function FilterReducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_FILTERSTATE:
            return Object.assign({}, state, action.value, {changed: true});
        case CHANGE_SEARCHSTATE:
            if (!action.value.fetchFacets) {
                return Object.assign({}, state, { changed: false});;
            } else {
                return defaultState;
            }
        default:
            return Object.assign({}, state, { changed: false});
    }
}