import { makeStyles } from '@material-ui/core/styles';

export default function useStyles() { 
    return makeStyles(theme => ({
        header:{
            display: 'flex',
        },
        headerImage: {
            maxHeight: 26,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            flex: '0 0 auto',
        },
        headerText: {
            flex: '1 1 auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
        },
        headerIcon: {
            fontSize: "0.9em",
            color: "#555"
        }
    }))();
}