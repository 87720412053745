import './styles.css';

import { Button, Chip } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux'

import DateRangeIcon from '@material-ui/icons/DateRange';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeOffIcon from '@material-ui/icons/FlightTakeoff';
import LinkIcon from '@material-ui/icons/Link';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ScheduleIcon from '@material-ui/icons/Schedule';
import config from '../../config'
import { useAppStore } from '../../context';
import useStyles from './classes';
import { SEARCH_MODE_PACKAGE } from '../../Reducers/SearchToolReducer';

export default function Content(props) {
    const { state } = useAppStore();
    const searchState = useSelector(state => state.SearchTool, shallowEqual);
    const classes = useStyles();

    let id = 0;
   
    const price = props.price;
    const selectPrice = props.selectPrice;
    const rooms = props.rooms;
    let totalPax = 0;
    rooms.forEach(r => totalPax += (r.adults + r.children));
    let totalPrice = 0;
    price.rooms.forEach(r => totalPrice += parseFloat(r.price));

    const dateFormatOptions = {
        weekday: 'long', year: '2-digit', month: 'short', day: 'numeric'
    };
    const departureDate = new Date(props.departureDate);
    const returnDate = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate() + props.duration);

    const departureComponent = searchState.searchMode == SEARCH_MODE_PACKAGE
     ? (<div className={classes.packageDetailsContainer}>
        <div className={classes.packageDetailsIcon}>
            <FlightTakeOffIcon className={classes.icon} />
        </div>
        <div className={classes.packageDetailsInfo}>
            {props.departureAirport}
        </div>
        <div className={classes.packageDetailsIcon}>
            <FlightLandIcon className={classes.icon} />
        </div>
        <div className={classes.packageDetailsInfo}>
            {props.arrivalAirport}
        </div>
    </div>)
    : null;

    return (
        <div className={classes.content}>
            <div className={classes.packageDetails}>
                <div className={classes.package}>
                    <div className={classes.packageDetailsContainer}>
                        <div className={classes.packageDetailsIcon}>
                            <RestaurantIcon className={classes.icon} />
                        </div>
                        <div className={classes.packageDetailsInfo}>
                            {props.prices.map(p =>
                                    <Chip 
                                        key={++id} 
                                        className={(p.mealBasisID===price.mealBasisID ? classes.priceChip : classes.altPriceChip)}
                                        onClick={()=>selectPrice(p)}
                                        label={p.mealBasis}></Chip>
                                )}
                        </div>
                    </div>
                    {departureComponent}
                    <div className={classes.packageDetailsContainer}>
                        <div className={classes.packageDetailsIcon}>
                            <DateRangeIcon className={classes.icon} />
                        </div>
                        <div className={classes.packageDetailsInfo}>
                            <strong>{departureDate.toLocaleDateString(undefined, dateFormatOptions)}</strong><span> to </span><strong>{returnDate.toLocaleDateString(undefined, dateFormatOptions)}</strong>
                        </div>
                    </div>
                    <div className={classes.packageDetailsContainer}>
                        <div className={classes.packageDetailsIcon}>
                            <ScheduleIcon className={classes.icon} />
                        </div>
                        <div className={classes.packageDetailsInfo}>
                            {props.duration} night{props.duration===1?'':'s'}
                        </div>
                    </div>                    
                    {
                        state.developmentMode && <div className={classes.packageDetailsContainer}>
                            <div className={classes.packageDetailsIcon}>
                                <LinkIcon className={classes.icon} />
                            </div>
                            <div className={classes.packageDetailsInfo}>
                                <a href={(config.BASE_URL + "details?ref=" + price.rooms[0].packageReference)} target="_blank">{price.rooms[0].packageReference}</a>
                            </div>
                        </div>
                    }
                    <div className={classes.spacer}></div>
                </div>
            </div>
            <div className={classes.cta}>
                <div className={classes.fromPrice}><span className={classes.fromPriceSub}>from</span> £{(totalPrice/totalPax).toFixed(2)} <span className={classes.fromPriceSub}>pp</span></div>
                <div className={classes.totalPrice}>£{totalPrice.toFixed(2)} total</div>
                <div className={classes.ctaButton}>
                    <Button className={classes.ctaButtonControl}>Book Now</Button>
                </div>
            </div>
        </div>
    );
}