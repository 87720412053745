export default function AvailabilityReducerFactory(actions, defaultData, reducers) {

    const defaultState = {
        loading: false,
        loaded: false,
        data: defaultData,
        error: false,
        warning: '',
        warningSeen: false,
    };

    return function(state = defaultState, action) {
        switch (action.type) {
            case actions.start:
                return Object.assign({}, state, { loading: true, loaded: false, error: false, warning: '', warningSeen: false });
            case actions.success:
                return Object.assign({}, state, { loading: false, loaded: true, error: false, data: reducers.success(action.values), warning: '', warningSeen: false });
            case actions.failure:
                return Object.assign({}, state, defaultState, { loading: false, loaded: true, error: true, warning: '', warningSeen: false });
            case actions.warning:
                return Object.assign({}, state, defaultState, { loading: false, loaded: true, error: false, warning: action.warning, warningSeen: false });
            case actions.reset:
                return defaultState;
            default:
                return state;
        }
    }
}