import "react-dates/lib/css/_datepicker.css";
import "./Components/DateRange/custom.css";
import "react-dates/initialize";
import "typeface-nunito";

import { AppContext, AppStoreProvider } from "./context";
import { Card, Collapse, Container, LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Alert } from "@material-ui/lab";
import CloseWarning from "./Actions/CloseWarning";
import DateFnsUtils from "@date-io/date-fns";
import FilterContainer from "./Components/FilterContainer";
import Header from "./Components/Header";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from "prop-types";
import PropertyResults from "./Components/PropertyResults";
import SearchTool from "./Components/SearchTool";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import UrlList from "./Components/UrlList";
import { makeStyles } from "@material-ui/core/styles";
import ChangeSearchState from './Actions/ChangeSearchState';
import { FACETS_CLEAR } from './Reducers/FacetReducer';
import { FetchAvailabilities} from './Actions/FetchAvailabilities';
import { PROPERTIES_CLEAR } from './Reducers/PropertyReducer';
import { SEARCH_MODE_PACKAGE, SEARCH_MODE_HOTELS_ONLY } from "./Reducers/SearchToolReducer";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flex: "1 0 auto",
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  app: {
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  filter: {
    padding: theme.spacing(2),
    flex: "1 0 auto",
  },
  toolbar: theme.mixins.toolbar,
  flex: {
    display: "flex",
  },

  results: {
    display: "flex",
    flexDirection: "row",
    marginTop: -10
  },
  filterWrapper: {
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    fontSize: "0.9rem"
  },
  resultsWrapper: {
    padding: theme.spacing(2),
    flexGrow: 1
  }
}));

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Nunito, Roboto, Helvetica, Arial, sans-serif",
    },
    palette: {
      secondary: {
        main: "#fdc530",
      },
      primary: {
        main: "#0082c2",
      },
    },
    hero: {
      backgroundColor: "ddd",
    },
  });
  const classes = useStyles(theme);
  const state = useSelector(state => state.Properties, shallowEqual);
  const searchToolState = useSelector(state => state.SearchTool, shallowEqual);
  const dispatch = useDispatch();
  const main = [];
  const warning = [];
  let id = 0;

  if (state.loading) {
    main.push(<LinearProgress key={++id} />);
  } else if (state.loaded && state.properties) {
    main.push(<div key={++id} className={classes.filterWrapper}><FilterContainer /></div>);
    main.push(<div key={++id} className={classes.resultsWrapper}><PropertyResults /></div>);
  } else if (state.error) {
    warning.push(
      <Collapse in={!state.warningSeen} key={++id}>
        <Alert
          className={classes.alert}
          severity="warning"
          onClose={() => CloseWarning(dispatch)}
        >
          An error has occurred on the server
        </Alert>
      </Collapse>
    );
  }

  // dispatch({type: FETCH_FACETS_SUCCESS, values: {"totalProperties":4230,"filteredProperties":1897,"attributes":{"2":1897,"8":120,"9":6,"10":47},"facilities":{"1":340,"2":219,"4":277,"8":750,"20":1230,"35":519,"37":116,"40":198,"42":379,"51":503,"56":403,"57":498,"74":21,"76":1575},"ratings":{"3":513,"4":1329,"5":55},"mealBases":{"1":1978,"2":594,"3":3206,"4":1123,"5":352,"6":605},"tripAdvisorRatings":{"5":24,"4":977,"3":1508,"2":1886,"1":1895,"0":1897},"priceBands":{"1":1629,"2":210,"3":19,"4":5,"5":34},"geographyLevel1s":{"93":58,"100":45,"73":252,"13":61,"72":37,"32":69,"45":22,"44":37,"41":47,"4":391,"39":73,"64":67,"30":28,"5":35,"59":22,"69":24,"34":9,"88":26,"48":14,"29":61,"2":87,"99":317,"85":11,"28":36,"55":7,"89":46,"20":15},"geographyLevel2s":{},"geographyLevel3s":{}}})
  const showResults = () => state.loading || (state.loaded && state.properties) || state.error;

  const changeSearchMode = (newState) => {
    newState = Object.assign(
        {},
        searchToolState,
        newState,
        {
            fetchFacets: true,
        }
    );
    if (newState.searchMode != searchToolState.searchMode) {
      dispatch({type: FACETS_CLEAR});
      dispatch({type: PROPERTIES_CLEAR});
    }
    FetchAvailabilities(newState, dispatch);
    ChangeSearchState(newState, dispatch);
  };

  return (
    <AppStoreProvider>
      <div className={classes.app}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <section className="header">
              <section className="header-hero">
                <Header />
                <img
                  className="header-hero__image"
                  src="/beach.jpg"
                  alt="Luxury Holiday"
                />
              </section>
              <Container className={classes.container}>
                <div className="header-tabs">
                  <Tabs value={searchToolState.searchMode} onChange={(_, newValue) => changeSearchMode({searchMode: newValue})} >
                    <Tab value={SEARCH_MODE_PACKAGE} label="Flight + Hotel" />
                    <Tab value={SEARCH_MODE_HOTELS_ONLY} label="Hotels" />
                  </Tabs>
                  <SearchTool />
                </div>
              </Container>
            </section>
            {
              showResults() && <Container className={classes.resultsContainer}>
                <UrlList />
                <Card className={classes.results}>
                  {main}
                </Card>
              </Container>
            }
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </AppStoreProvider>
  );
}

export default App;
